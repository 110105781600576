const instructors = [
  {
    instructor: "Dalkin, James",
    rating: 4,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Petacchi, Reining",
    rating: 4.7,
    percent: "90%",
    difficulty: 3.4,
  },
  {
    instructor: "Simon, Nina",
    rating: 3.9,
    percent: "82%",
    difficulty: 2.9,
  },
  {
    instructor: "Cooke, Thomas",
    rating: 4.6,
    percent: "83%",
    difficulty: 2.4,
  },
  {
    instructor: "Zotova, Viktoriya",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Kitching, Karen",
    rating: 3.6,
    percent: "62%",
    difficulty: 3.5,
  },
  {
    instructor: "Anderson, Kirsten",
    rating: 3.8,
    percent: "83%",
    difficulty: 3.6,
  },
  {
    instructor: "Nguyen, April",
    rating: 3,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Rouchard, Mark",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Hilary, Gilles",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Koester, Allison",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Patterson, Robert",
    rating: 2.8,
    percent: "36%",
    difficulty: 3.5,
  },
  {
    instructor: "White, Melanie",
    rating: 2,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Ndubuizu, Rosemary",
    rating: 4.3,
    percent: "83%",
    difficulty: 4.3,
  },
  {
    instructor: "Adams, Brienne",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Robinson, Zandria",
    rating: 4,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Gore, Dayo",
    rating: 3.5,
    percent: "N/A",
    difficulty: 3.7,
  },
  {
    instructor: "Gonzalez, Anita",
    rating: 2.7,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Mitchell, Angelyn",
    rating: 4.6,
    percent: "75%",
    difficulty: 2.8,
  },
  {
    instructor: "Muaka, Leonard",
    rating: 4.3,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Otu, Kwame",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Inman, Kris",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Park, Yoon",
    rating: 5,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Tilan, Jason",
    rating: 2.5,
    percent: "39%",
    difficulty: 4,
  },
  {
    instructor: "Sherman, Thomas",
    rating: 3,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Linkon, Sherry",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Seamon, Erika",
    rating: 5,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Weissenstein, Colva",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Ibrahim, Amrita",
    rating: 4.3,
    percent: "88%",
    difficulty: 2.2,
  },
  {
    instructor: "Benessaiah, Nejm",
    rating: 4.1,
    percent: "93%",
    difficulty: 2.4,
  },
  {
    instructor: "King-Irani, Laurie",
    rating: 3.6,
    percent: "77%",
    difficulty: 1.4,
  },
  {
    instructor: "Onder, Sylvia",
    rating: 4,
    percent: "72%",
    difficulty: 2.8,
  },
  {
    instructor: "Pearson, Heath",
    rating: 3.7,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Bickford, Andrew",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Brennan, Denise",
    rating: 4.4,
    percent: "75%",
    difficulty: 3.1,
  },
  {
    instructor: "Jebnoun, Noureddine",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Adely, Fida",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Davis, Rochelle",
    rating: 3.8,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Perry, Jackson",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Al Ali, Ghayda",
    rating: 3.7,
    percent: "64%",
    difficulty: 4.4,
  },
  {
    instructor: "Alzoubi, Elham",
    rating: 5,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Kilany, Hanaa",
    rating: 3.9,
    percent: "75%",
    difficulty: 4.1,
  },
  {
    instructor: "Al-Mufti, Huda",
    rating: 3.6,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Hariry, Seerwan",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Zabarah, Hana",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Chamseddine, Abdul Rahman",
    rating: 3,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Fazza, Hany",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Baccouche, Belkacem",
    rating: 3.5,
    percent: "50%",
    difficulty: 3.7,
  },
  {
    instructor: "Mohamed, Yehia",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Khalifah, Omar",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Alqassas, Ahmad",
    rating: 3,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Stetkevych, Suzanne",
    rating: 3.1,
    percent: "0%",
    difficulty: 2.8,
  },
  {
    instructor: "Opwis, Felicitas",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Colla, Elliott",
    rating: 3.5,
    percent: "72%",
    difficulty: 3.7,
  },
  {
    instructor: "Yu, Ji",
    rating: 2.5,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Hutchison, Scott",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Schlesinger, Ann",
    rating: 4,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Barnhart, Scip",
    rating: 4.8,
    percent: "100%",
    difficulty: 1.1,
  },
  {
    instructor: "Carr-Shaffer, Kelly",
    rating: 4.9,
    percent: "100%",
    difficulty: 1.8,
  },
  {
    instructor: "Mckaig, Bruce",
    rating: 2.1,
    percent: "15%",
    difficulty: 3.5,
  },
  {
    instructor: "Bocci, Roberto",
    rating: 3.5,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Reed, Evan",
    rating: 4.1,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Sandberg, Erik",
    rating: 4.1,
    percent: "64%",
    difficulty: 3.1,
  },
  {
    instructor: "Dowley, Michael",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Nahidian, Negar",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Sangastiano, Toni-Lee",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Kelly, Elyse",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Robinson, Elizabeth Brady",
    rating: 4,
    percent: "50%",
    difficulty: 2,
  },
  {
    instructor: "Keesling, Catherine",
    rating: 4.6,
    percent: "93%",
    difficulty: 2.6,
  },
  {
    instructor: "Tilney, Barrett",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Huezo, Andrea",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Acres, Al",
    rating: 4.9,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Hunt, Tiffany",
    rating: 1.3,
    percent: "N/A",
    difficulty: 4.7,
  },
  {
    instructor: "Bourland, William",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Rufino, Stephanie",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Lee, Jungsil",
    rating: 2.5,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Prelinger, Elizabeth",
    rating: 4.1,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Wang, Michelle",
    rating: 4.4,
    percent: "78%",
    difficulty: 2.6,
  },
  {
    instructor: "Strong, Lisa",
    rating: 1.9,
    percent: "0%",
    difficulty: 4.1,
  },
  {
    instructor: "Looney, Kristen",
    rating: 4.3,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Van Hollen, Cecilia",
    rating: 3.2,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Sodhy, Pamela",
    rating: 3.8,
    percent: "80%",
    difficulty: 2,
  },
  {
    instructor: "Wilder, Dennis",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Tajima, Yuhki",
    rating: 4.9,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Ali, Tariq",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Lee, Yeonju",
    rating: 4,
    percent: "50%",
    difficulty: 3.5,
  },
  {
    instructor: "Cha, Victor",
    rating: 3.6,
    percent: "50%",
    difficulty: 3.3,
  },
  {
    instructor: "Hammer, Alexander",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Medeiros, Evan",
    rating: 3,
    percent: "75%",
    difficulty: 4,
  },
  {
    instructor: "Brown, Gregory",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Tidwell, Alan",
    rating: 4.6,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Mullen, John",
    rating: 4,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Dym, Martin",
    rating: 4,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Jones, Jessica",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Martin, Mary",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Girgis, Michael",
    rating: 4.2,
    percent: "67%",
    difficulty: 3.3,
  },
  {
    instructor: "Schmidt, Marcel",
    rating: 5,
    percent: "N/A",
    difficulty: 1.7,
  },
  {
    instructor: "Johnson, Michael",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Suter, Robert",
    rating: 5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Al-Ali, Rasha",
    rating: 4.9,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Rosenthal, Dean",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Rosenthal, Cynthia",
    rating: 4.5,
    percent: "N/A",
    difficulty: 1.9,
  },
  {
    instructor: "Chang, Esther",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "John, Sinu",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Amri, Hakima",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Nair, Sreejith",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Bray, Mike",
    rating: 4.6,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Steen, Tomoko",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Duvall, Christopher",
    rating: 4.1,
    percent: "75%",
    difficulty: 3,
  },
  {
    instructor: "Carus, Seth",
    rating: 5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Stiefel, David",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Sharkey, Matthew",
    rating: 2.8,
    percent: "50%",
    difficulty: 3.3,
  },
  {
    instructor: "Ross, Karen",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Edwards, Nathan",
    rating: 3.3,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Hahn, William",
    rating: 4.5,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Fox, Jennifer",
    rating: 4.1,
    percent: "88%",
    difficulty: 2.7,
  },
  {
    instructor: "Chan, Mun",
    rating: 4.5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Elmendorf, Heidi",
    rating: 4.7,
    percent: "84%",
    difficulty: 3.9,
  },
  {
    instructor: "Coate, Thomas",
    rating: 4,
    percent: "67%",
    difficulty: 4,
  },
  {
    instructor: "Floyd, Jeanetta",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Audette, Dylan",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Patten, Manus",
    rating: 4.3,
    percent: "97%",
    difficulty: 3.3,
  },
  {
    instructor: "Rosenwald, Anne",
    rating: 4.2,
    percent: "75%",
    difficulty: 3.8,
  },
  {
    instructor: "Brinsmade, Shaun",
    rating: 1.5,
    percent: "8%",
    difficulty: 4.1,
  },
  {
    instructor: "Rolfes, Ronda",
    rating: 2.8,
    percent: "53%",
    difficulty: 3.7,
  },
  {
    instructor: "Bennett, Shauna",
    rating: 3.5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Isaac, Daniel",
    rating: 4.4,
    percent: "88%",
    difficulty: 3.3,
  },
  {
    instructor: "Newfield, Timothy",
    rating: 4.5,
    percent: "94%",
    difficulty: 3.2,
  },
  {
    instructor: "Parker, Michael",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Dzakpasu, Rhonda",
    rating: 4.8,
    percent: "50%",
    difficulty: 2,
  },
  {
    instructor: "Singer, Steven",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Maguire-Zeiss, Kathleen",
    rating: 4.5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Cote, Paul",
    rating: 3.5,
    percent: "50%",
    difficulty: 3.2,
  },
  {
    instructor: "Loffredo, Christopher",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Dash, Chiranjeev",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Peshkin, Beth",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Neale, Joseph",
    rating: 3.4,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Huang, Jeffrey",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Wimp, Gina",
    rating: 4,
    percent: "72%",
    difficulty: 3.2,
  },
  {
    instructor: "Hamilton, Matthew",
    rating: 2.5,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "Bansal, Shweta",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Cervantes Sandoval, Isaac",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Shepard, Blythe",
    rating: 4.7,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Theos, Alexander",
    rating: 4.3,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "Mann, Janet",
    rating: 3.4,
    percent: "50%",
    difficulty: 3.1,
  },
  {
    instructor: "Silva, Elena",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Ries, Leslie",
    rating: 2.7,
    percent: "34%",
    difficulty: 3,
  },
  {
    instructor: "Weiss, Martha",
    rating: 2.6,
    percent: "45%",
    difficulty: 3.4,
  },
  {
    instructor: "Armbruster, Peter",
    rating: 3.5,
    percent: "50%",
    difficulty: 3.3,
  },
  {
    instructor: "Rose, Mark",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Marra, Peter",
    rating: 4.5,
    percent: "89%",
    difficulty: 1.8,
  },
  {
    instructor: "Johnson, Sarah",
    rating: 4.9,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "van Doorn, Angela",
    rating: 4.8,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Helm, Rebecca",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Fischer, Julie",
    rating: 4.5,
    percent: "70%",
    difficulty: 3.5,
  },
  {
    instructor: "Korostyshevskiy, Valeriy",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Li, Xin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Dragomir, Anca",
    rating: 3.5,
    percent: "64%",
    difficulty: 2.7,
  },
  {
    instructor: "Fang, Hongbin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Tan, Ming",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Donahue, Robert",
    rating: 1,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Kisailus, Edward",
    rating: 3.8,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Goodstein, Ronald",
    rating: 4.3,
    percent: "86%",
    difficulty: 2.5,
  },
  {
    instructor: "Bies, Robert",
    rating: 4.6,
    percent: "92%",
    difficulty: 2,
  },
  {
    instructor: "Dong, Arthur",
    rating: 4.1,
    percent: "63%",
    difficulty: 2.4,
  },
  {
    instructor: "Ernst, Ricardo",
    rating: 3,
    percent: "63%",
    difficulty: 3.4,
  },
  {
    instructor: "Logg, Jennifer",
    rating: 2.5,
    percent: "28%",
    difficulty: 3.7,
  },
  {
    instructor: "Cypher, Matthew",
    rating: 4,
    percent: "84%",
    difficulty: 2.5,
  },
  {
    instructor: "Reid, Jeffrey",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Lovegrove, Nicholas",
    rating: 4.1,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Sherman, Eliot",
    rating: 3,
    percent: "0%",
    difficulty: 3.5,
  },
  {
    instructor: "Dupont, Quentin",
    rating: 5,
    percent: "80%",
    difficulty: 4,
  },
  {
    instructor: "Schloetzer, Jason",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Smith, Justin",
    rating: 5,
    percent: "100%",
    difficulty: 1.5,
  },
  {
    instructor: "Pacheco, Rachel",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Chauvin, Jasmina",
    rating: 2.3,
    percent: "58%",
    difficulty: 2,
  },
  {
    instructor: "Hasnas, John",
    rating: 2.4,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Washington, Ella",
    rating: 3.4,
    percent: "67%",
    difficulty: 2.8,
  },
  {
    instructor: "Rivoli, Pietra",
    rating: 4.1,
    percent: "N/A",
    difficulty: 2.9,
  },
  {
    instructor: "Zafari, Babak",
    rating: 4.5,
    percent: "86%",
    difficulty: 3.1,
  },
  {
    instructor: "Mayo, John",
    rating: 3.1,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Annan, Anthony",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Collins, David",
    rating: 4.2,
    percent: "95%",
    difficulty: 3.5,
  },
  {
    instructor: "Stiefel, Jeffrey",
    rating: 3,
    percent: "59%",
    difficulty: 3.8,
  },
  {
    instructor: "Stoll, Sarah",
    rating: 2.4,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Bertke, Michelle",
    rating: 4.6,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Itani, Mohammad",
    rating: 3.7,
    percent: "84%",
    difficulty: 3.3,
  },
  {
    instructor: "Zimerman, Oscar",
    rating: 1,
    percent: "46%",
    difficulty: 2.7,
  },
  {
    instructor: "Glick, Diana",
    rating: 4.2,
    percent: "79%",
    difficulty: 2.5,
  },
  {
    instructor: "de Dios, Angel",
    rating: 2,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Presores, Janeth",
    rating: 2.1,
    percent: "43%",
    difficulty: 3.3,
  },
  {
    instructor: "Davis, Ronald",
    rating: 3.1,
    percent: "60%",
    difficulty: 3.5,
  },
  {
    instructor: "Holman, Kevin",
    rating: 3.2,
    percent: "56%",
    difficulty: 4.6,
  },
  {
    instructor: "Shahu, Milena",
    rating: 2.8,
    percent: "86%",
    difficulty: 4,
  },
  {
    instructor: "Braselmann, Esther",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Hahm, Jong-in",
    rating: 2.5,
    percent: "34%",
    difficulty: 2.7,
  },
  {
    instructor: "Swift, Jennifer",
    rating: 3.4,
    percent: "67%",
    difficulty: 3.8,
  },
  {
    instructor: "Wolf, Christian",
    rating: 3,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "Knope, Karah",
    rating: 3.2,
    percent: "34%",
    difficulty: 3.4,
  },
  {
    instructor: "Metallo, Steven",
    rating: 3.3,
    percent: "80%",
    difficulty: 4,
  },
  {
    instructor: "Weiss, Richard",
    rating: 2.7,
    percent: "34%",
    difficulty: 4.6,
  },
  {
    instructor: "Tong, YuYe",
    rating: 2.8,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Roepe, Paul",
    rating: 2.4,
    percent: "50%",
    difficulty: 4.3,
  },
  {
    instructor: "Ichiye, Toshiko",
    rating: 2.9,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Maillard, Rodrigo",
    rating: 4.5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Yang, Yu-Sheng",
    rating: 4.6,
    percent: "93%",
    difficulty: 3.4,
  },
  {
    instructor: "Wang, Peng",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Wang, Sicheng",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Qi, Di",
    rating: 4.4,
    percent: "85%",
    difficulty: 3.2,
  },
  {
    instructor: "Yu, Chao",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Yu, Pei-Shan",
    rating: 5,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Kafalas, Philip",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Ren, Fei",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Shernuk, Kyle",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Osgood, Josiah",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Catenaccio, Claire",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "McNelis, Charles",
    rating: 4.7,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Merritt, Andrew",
    rating: 4,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Haynes, Justin",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Sens, Alexander",
    rating: 4.7,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "Dull, David",
    rating: 3.3,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Herold, Mary",
    rating: 2.7,
    percent: "34%",
    difficulty: 3.3,
  },
  {
    instructor: "Buckley, Bryan",
    rating: 5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Miller, Kristen",
    rating: 4.9,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Vittone, Sarah",
    rating: 3.4,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Turner, Jeanine",
    rating: 4.1,
    percent: "84%",
    difficulty: 3.1,
  },
  {
    instructor: "Koliska, Michael",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Bode, Leticia",
    rating: 3,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Seng-White, Erica",
    rating: 3.7,
    percent: "75%",
    difficulty: 3.2,
  },
  {
    instructor: "McDonald, Mark",
    rating: 4.1,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Campbell, Tyrel",
    rating: 2.7,
    percent: "N/A",
    difficulty: 3.2,
  },
  {
    instructor: "Barba, Evan",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Matthews, Robert",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Macovski, Michael",
    rating: 3.4,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Bedford, Denise",
    rating: 3,
    percent: "N/A",
    difficulty: 1.5,
  },
  {
    instructor: "Wilson, Christine",
    rating: 3.9,
    percent: "75%",
    difficulty: 3.9,
  },
  {
    instructor: "Buffum, Philip",
    rating: 3,
    percent: "62%",
    difficulty: 3,
  },
  {
    instructor: "Woods, W.",
    rating: 4.8,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Essick, Raymond",
    rating: 3.9,
    percent: "73%",
    difficulty: 3.3,
  },
  {
    instructor: "Montgomery, Jami",
    rating: 2.6,
    percent: "32%",
    difficulty: 3.5,
  },
  {
    instructor: "Velauthapillai, Mahendran",
    rating: 3.8,
    percent: "87%",
    difficulty: 3.2,
  },
  {
    instructor: "Fineman, Jeremy",
    rating: 5,
    percent: "100%",
    difficulty: 4.2,
  },
  {
    instructor: "Sherr, Micah",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Squier, Richard",
    rating: 3.8,
    percent: "75%",
    difficulty: 2.9,
  },
  {
    instructor: "Shields, Thomas",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Kalyanasundaram, Bala",
    rating: 3.1,
    percent: "40%",
    difficulty: 4.1,
  },
  {
    instructor: "Yang, Grace",
    rating: 3,
    percent: "56%",
    difficulty: 3.8,
  },
  {
    instructor: "Goharian, Nazli",
    rating: 2.6,
    percent: "37%",
    difficulty: 4.1,
  },
  {
    instructor: "Newport, Calvin",
    rating: 4.5,
    percent: "90%",
    difficulty: 3.7,
  },
  {
    instructor: "Singh, Lisa",
    rating: 3.6,
    percent: "38%",
    difficulty: 3.9,
  },
  {
    instructor: "Ujcich, Benjamin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Chandler, Katherine",
    rating: 5,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Goswami, Supriya",
    rating: 4.4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Oruc, Firat",
    rating: 4.8,
    percent: "100%",
    difficulty: 1.5,
  },
  {
    instructor: "Sonbol, Amira",
    rating: 4.1,
    percent: "67%",
    difficulty: 2.5,
  },
  {
    instructor: "Pirrotti, Anthony",
    rating: 4.9,
    percent: "N/A",
    difficulty: 1.3,
  },
  {
    instructor: "Almond, Ian",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Koons, Jeremy",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Hickman, James",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Jacobs, Jeffrey",
    rating: 4.8,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Padalkar, Nakul",
    rating: 4.5,
    percent: "90%",
    difficulty: 2.7,
  },
  {
    instructor: "Bolton, Jeremy",
    rating: 3.9,
    percent: "75%",
    difficulty: 3.7,
  },
  {
    instructor: "Gamage, Purna",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Larson, Chris",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Arora, Amit",
    rating: 4.8,
    percent: "N/A",
    difficulty: 1.5,
  },
  {
    instructor: "Vaisman, Marck",
    rating: 1.2,
    percent: "0%",
    difficulty: 4.6,
  },
  {
    instructor: "Dasgupta, Abhijit",
    rating: 3.3,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Zahadat, Nima",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Evans, Brian",
    rating: 2.5,
    percent: "N/A",
    difficulty: 3.3,
  },
  {
    instructor: "Kerr, Michael",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Miller, Rory",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Ferris, Elizabeth",
    rating: 4.3,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Joshi, Shareen",
    rating: 4.5,
    percent: "87%",
    difficulty: 3,
  },
  {
    instructor: "Schneider, Cynthia",
    rating: 2.9,
    percent: "67%",
    difficulty: 1.7,
  },
  {
    instructor: "Brown, Lydia",
    rating: 3.7,
    percent: "84%",
    difficulty: 1.3,
  },
  {
    instructor: "Rifkin, Libbie",
    rating: 4.4,
    percent: "89%",
    difficulty: 2.8,
  },
  {
    instructor: "Burk, David",
    rating: 4.4,
    percent: "99%",
    difficulty: 3,
  },
  {
    instructor: "Garcia Couto, Santiago",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Cobo Reyes Cano, Ramon",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Ostry, Jonathan",
    rating: 3,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Diba, Behzad",
    rating: 3.8,
    percent: "67%",
    difficulty: 2.9,
  },
  {
    instructor: "Schwartz, Marius",
    rating: 2.9,
    percent: "83%",
    difficulty: 3.9,
  },
  {
    instructor: "Genicot, Garance",
    rating: 3,
    percent: "56%",
    difficulty: 4,
  },
  {
    instructor: "Cao, Dan",
    rating: 2.8,
    percent: "50%",
    difficulty: 3.5,
  },
  {
    instructor: "Reischer, Margit",
    rating: 3.2,
    percent: "72%",
    difficulty: 4,
  },
  {
    instructor: "Huggett, Mark",
    rating: 3,
    percent: "50%",
    difficulty: 3.4,
  },
  {
    instructor: "Solow, Benjamin",
    rating: 2.9,
    percent: "80%",
    difficulty: 4.3,
  },
  {
    instructor: "Bester, Alan",
    rating: 4.6,
    percent: "98%",
    difficulty: 4.2,
  },
  {
    instructor: "Verma, Rubina",
    rating: 3.3,
    percent: "68%",
    difficulty: 3.3,
  },
  {
    instructor: "Wilber, Pinar",
    rating: 3.5,
    percent: "64%",
    difficulty: 3.1,
  },
  {
    instructor: "McCornac, Dennis",
    rating: 5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Hasanov, Fuad",
    rating: 3.4,
    percent: "81%",
    difficulty: 3.7,
  },
  {
    instructor: "Austin, Wes",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Parthum, Bryan",
    rating: 5,
    percent: "100%",
    difficulty: 3.7,
  },
  {
    instructor: "Hamza, Ali",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Bronson, Mary",
    rating: 5,
    percent: "100%",
    difficulty: 1.5,
  },
  {
    instructor: "Laage, Louise",
    rating: 3,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Madigan, Brian",
    rating: 3,
    percent: "50%",
    difficulty: 2.5,
  },
  {
    instructor: "Anderson, Axel",
    rating: 3.2,
    percent: "69%",
    difficulty: 3.3,
  },
  {
    instructor: "Chambers, Christopher",
    rating: 3,
    percent: "34%",
    difficulty: 3,
  },
  {
    instructor: "Chang, Minsu",
    rating: 4.9,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Poirier, Alexandre",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Vella, Francis",
    rating: 2.1,
    percent: "N/A",
    difficulty: 4.5,
  },
  {
    instructor: "Bouton, Laurent",
    rating: 3,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Ko, Ami",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Ludema, Rodney",
    rating: 3.6,
    percent: "45%",
    difficulty: 3.4,
  },
  {
    instructor: "Ghebreab, Nardos",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Wesley-Nero, Sabrina",
    rating: 2.2,
    percent: "20%",
    difficulty: 3.6,
  },
  {
    instructor: "Steffen, Heather",
    rating: 4.3,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Ortiz, Ricardo",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Hensley, Nathan",
    rating: 4.8,
    percent: "92%",
    difficulty: 2.7,
  },
  {
    instructor: "Perlow, Seth",
    rating: 3.7,
    percent: "80%",
    difficulty: 2.1,
  },
  {
    instructor: "Carozza, Davide",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Bump, Nathaniel",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Parsons, Coilin",
    rating: 4.4,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Barnett, Mary Jane",
    rating: 3.8,
    percent: "77%",
    difficulty: 2.8,
  },
  {
    instructor: "LeRoy, Tamar",
    rating: 5,
    percent: "N/A",
    difficulty: 1.3,
  },
  {
    instructor: "Wu, Duncan",
    rating: 4.9,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Chander, Manu",
    rating: 4.4,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Rizzuto, Nicole",
    rating: 4.8,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Frampton, Edith",
    rating: 4.6,
    percent: "93%",
    difficulty: 2.7,
  },
  {
    instructor: "Boylan, Rebecca",
    rating: 3.8,
    percent: "82%",
    difficulty: 3.5,
  },
  {
    instructor: "El-Amine, Zein",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Shinn, Christopher",
    rating: 4.4,
    percent: "95%",
    difficulty: 2.3,
  },
  {
    instructor: "Gorman, Ellen",
    rating: 4.5,
    percent: "93%",
    difficulty: 1.4,
  },
  {
    instructor: "Morrison, Amani",
    rating: 1.5,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Lee, Peggy",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Fox, Pamela",
    rating: 4.2,
    percent: "67%",
    difficulty: 2.8,
  },
  {
    instructor: "Schotland, Sara",
    rating: 4.6,
    percent: "100%",
    difficulty: 1.8,
  },
  {
    instructor: "Cole, Kelly",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Phillips, Amanda",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Fink, Jennifer",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Bosco, Mark",
    rating: 4.4,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Fuisz, Lisbeth",
    rating: 4.2,
    percent: "87%",
    difficulty: 2.2,
  },
  {
    instructor: "McMorris, Mark",
    rating: 2.8,
    percent: "40%",
    difficulty: 3.2,
  },
  {
    instructor: "Glavin, John",
    rating: 3.2,
    percent: "78%",
    difficulty: 4,
  },
  {
    instructor: "Hirsh, John",
    rating: 4.3,
    percent: "100%",
    difficulty: 1.7,
  },
  {
    instructor: "Hochman, Brian",
    rating: 4.1,
    percent: "67%",
    difficulty: 3,
  },
  {
    instructor: "Corrigan, Maureen",
    rating: 3.3,
    percent: "75%",
    difficulty: 2.8,
  },
  {
    instructor: "Benson-Allott, Caetlin",
    rating: 4.7,
    percent: "67%",
    difficulty: 2.6,
  },
  {
    instructor: "So, Christine",
    rating: 4,
    percent: "90%",
    difficulty: 3.3,
  },
  {
    instructor: "Temple, Kathryn",
    rating: 4,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Shore, Daniel",
    rating: 4.3,
    percent: "60%",
    difficulty: 2.9,
  },
  {
    instructor: "Stanga, Jane",
    rating: 5,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Agrawal, Vishal",
    rating: 4.6,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Griffiths, Brian",
    rating: 4.5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Guthrie, William",
    rating: 4.1,
    percent: "N/A",
    difficulty: 1.3,
  },
  {
    instructor: "Walsh, Edward",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Amster, Randall",
    rating: 4.6,
    percent: "92%",
    difficulty: 2.1,
  },
  {
    instructor: "Moulton, Orissa",
    rating: 4.5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Della Volla, Wesley",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Wei, Cynthia",
    rating: 3.7,
    percent: "66%",
    difficulty: 3.9,
  },
  {
    instructor: "Danner-McDonald, Kerry",
    rating: 3.6,
    percent: "80%",
    difficulty: 2.9,
  },
  {
    instructor: "Kamrad, Bardia",
    rating: 4,
    percent: "100%",
    difficulty: 4.4,
  },
  {
    instructor: "McDermott, Michael",
    rating: 3.4,
    percent: "50%",
    difficulty: 2.6,
  },
  {
    instructor: "Peck, Jeffrey",
    rating: 4.4,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Desai, Raj",
    rating: 3.9,
    percent: "69%",
    difficulty: 3.3,
  },
  {
    instructor: "Nollen, Stanley",
    rating: 4,
    percent: "0%",
    difficulty: 2.9,
  },
  {
    instructor: "Weymouth, Stephen",
    rating: 3.8,
    percent: "67%",
    difficulty: 2.8,
  },
  {
    instructor: "Macher, Jeffrey",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Almeida, Paul",
    rating: 4.9,
    percent: "N/A",
    difficulty: 3.8,
  },
  {
    instructor: "Jaworski, Peter",
    rating: 3.5,
    percent: "80%",
    difficulty: 3.1,
  },
  {
    instructor: "Wathieu, Luc",
    rating: 4.5,
    percent: "91%",
    difficulty: 2.4,
  },
  {
    instructor: "Gilad, Shye",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Holladay, James",
    rating: 3.2,
    percent: "67%",
    difficulty: 4.3,
  },
  {
    instructor: "Britton, Robert",
    rating: 4.1,
    percent: "91%",
    difficulty: 3.4,
  },
  {
    instructor: "Berry, Heather",
    rating: 5,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Bruno, Melissa",
    rating: 5,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Orsitto, Fulvio",
    rating: 4.4,
    percent: "N/A",
    difficulty: 1.6,
  },
  {
    instructor: "Sitney, Rebecca",
    rating: 4.8,
    percent: "100%",
    difficulty: 1.5,
  },
  {
    instructor: "Gao, Janet",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Wen, Quan",
    rating: 3.3,
    percent: "64%",
    difficulty: 3.2,
  },
  {
    instructor: "Bai, Jennie",
    rating: 2.9,
    percent: "55%",
    difficulty: 3.6,
  },
  {
    instructor: "Zhao, Xiaofei",
    rating: 4.3,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Nagel, Kelly",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Musalem, Alberto",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Crawford, Edward",
    rating: 3.8,
    percent: "50%",
    difficulty: 3.5,
  },
  {
    instructor: "Aggarwal, Reena",
    rating: 4.5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Smith, Rory",
    rating: 3,
    percent: "67%",
    difficulty: 3.5,
  },
  {
    instructor: "Dahiya, Sandeep",
    rating: 4.9,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Pinkowitz, Lee",
    rating: 4.5,
    percent: "75%",
    difficulty: 4.4,
  },
  {
    instructor: "Comer, George",
    rating: 4,
    percent: "67%",
    difficulty: 3,
  },
  {
    instructor: "Foster, Jeffrey",
    rating: 3.5,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Wilkinson, John",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Gupta, Arun",
    rating: 3,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Angel, James",
    rating: 2.9,
    percent: "50%",
    difficulty: 3.4,
  },
  {
    instructor: "Emmitte, Aaron",
    rating: 4.8,
    percent: "95%",
    difficulty: 2.5,
  },
  {
    instructor: "Cohen-Scali, Stella",
    rating: 2.4,
    percent: "25%",
    difficulty: 3.8,
  },
  {
    instructor: "Janssens, Peter",
    rating: 5,
    percent: "100%",
    difficulty: 1.5,
  },
  {
    instructor: "Twohig, Erin",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Sanderson, Jason",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Spielmann, Guy",
    rating: 3,
    percent: "40%",
    difficulty: 2.7,
  },
  {
    instructor: "Mirsharif, Zohreh",
    rating: 3.3,
    percent: "73%",
    difficulty: 3.5,
  },
  {
    instructor: "Daumas, Guillaume",
    rating: 3.2,
    percent: "75%",
    difficulty: 3.9,
  },
  {
    instructor: "Durmelat, Sylvie",
    rating: 4.4,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Young, Paul",
    rating: 4.1,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Erradi, Nezha",
    rating: 4.2,
    percent: "92%",
    difficulty: 2.6,
  },
  {
    instructor: "Webel, Alissa",
    rating: 4.8,
    percent: "94%",
    difficulty: 3.3,
  },
  {
    instructor: "Smorodinsky, Iris",
    rating: 4.6,
    percent: "94%",
    difficulty: 1.8,
  },
  {
    instructor: "Johnson, Joseph",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Grand Favre, Christine",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Boum Make, Jennifer",
    rating: 4.3,
    percent: "92%",
    difficulty: 3.5,
  },
  {
    instructor: "Andrade, Amandine",
    rating: 4.5,
    percent: "93%",
    difficulty: 3,
  },
  {
    instructor: "Le Guelte, Johann",
    rating: 4.8,
    percent: "80%",
    difficulty: 2.8,
  },
  {
    instructor: "Santoro, Milena",
    rating: 4.2,
    percent: "63%",
    difficulty: 3.8,
  },
  {
    instructor: "Bridaa, Boutheina",
    rating: 4.4,
    percent: "96%",
    difficulty: 2.5,
  },
  {
    instructor: "Tissot, Damien",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Sobanet, Andrew",
    rating: 4.2,
    percent: "91%",
    difficulty: 3.6,
  },
  {
    instructor: "Lee, Susanna",
    rating: 4.2,
    percent: "86%",
    difficulty: 2.6,
  },
  {
    instructor: "Simpson, Sally",
    rating: 2.6,
    percent: "0%",
    difficulty: 3.8,
  },
  {
    instructor: "Banchoff, Anja",
    rating: 4.5,
    percent: "77%",
    difficulty: 2.9,
  },
  {
    instructor: "Potter, John",
    rating: 3.6,
    percent: "100%",
    difficulty: 3.7,
  },
  {
    instructor: "Dougherty, Cooper",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Weigert, Astrid",
    rating: 4.2,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Kick, Verena",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Speggiorin, Maria",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Dupree, Mary",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Sieg, Katrin",
    rating: 2.7,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Eigler, Friederike",
    rating: 2.5,
    percent: "50%",
    difficulty: 3.4,
  },
  {
    instructor: "Zimmer, Thomas",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Wolf, Holger",
    rating: 3.7,
    percent: "N/A",
    difficulty: 1.4,
  },
  {
    instructor: "Newman, Abraham",
    rating: 4.9,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Mushaben, Joyce",
    rating: 3.2,
    percent: "0%",
    difficulty: 4.5,
  },
  {
    instructor: "Anderson, Jeffrey",
    rating: 5,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Klein, Christopher",
    rating: 4.8,
    percent: "N/A",
    difficulty: 1.8,
  },
  {
    instructor: "Pireddu, Nicoletta",
    rating: 4.1,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Denischenko, Irina",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Ganapati, Sharat",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Murphy, Dale",
    rating: 4.5,
    percent: "80%",
    difficulty: 4.3,
  },
  {
    instructor: "Busch, Marc",
    rating: 3.9,
    percent: "75%",
    difficulty: 4.2,
  },
  {
    instructor: "Conway, Thomas",
    rating: 4.7,
    percent: "100%",
    difficulty: 1.7,
  },
  {
    instructor: "Novelli, Catherine",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Senyuz, Eylem",
    rating: 3,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "King, Robin",
    rating: 3.9,
    percent: "50%",
    difficulty: 3,
  },
  {
    instructor: "Wheeler, Jeffrey",
    rating: 4,
    percent: "0%",
    difficulty: 2,
  },
  {
    instructor: "Wahid, Shabab",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Vuckovic, Myriam",
    rating: 4,
    percent: "88%",
    difficulty: 2.6,
  },
  {
    instructor: "Jarawan, Eva",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Wu, Zeng",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Baker, Margaret",
    rating: 2.4,
    percent: "N/A",
    difficulty: 3.4,
  },
  {
    instructor: "Galan, Deise",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Quattrochi, John",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Bruce, Kerry",
    rating: 3.8,
    percent: "69%",
    difficulty: 3.2,
  },
  {
    instructor: "Turbat, Vincent",
    rating: 1.7,
    percent: "34%",
    difficulty: 4.3,
  },
  {
    instructor: "Liese, Bernhard",
    rating: 2.8,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Radelet, Steven",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2.7,
  },
  {
    instructor: "Udomsaph, Charles",
    rating: 3.6,
    percent: "67%",
    difficulty: 3,
  },
  {
    instructor: "Lane, Sarah",
    rating: 4.8,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Apter, Felice",
    rating: 1,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Nelson, Martha",
    rating: 1.7,
    percent: "N/A",
    difficulty: 4.5,
  },
  {
    instructor: "Katz, Rebecca",
    rating: 3.7,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Hartman, Joseph",
    rating: 4.9,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Langenbacher, Eric",
    rating: 3.6,
    percent: "74%",
    difficulty: 3.1,
  },
  {
    instructor: "Carnes, Matthew",
    rating: 4.3,
    percent: "64%",
    difficulty: 3.9,
  },
  {
    instructor: "Sigillo, Ester",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Schlickenmaier, William",
    rating: 4.4,
    percent: "97%",
    difficulty: 3.4,
  },
  {
    instructor: "Nexon, Daniel",
    rating: 3.6,
    percent: "80%",
    difficulty: 2.9,
  },
  {
    instructor: "Fisher, Mark",
    rating: 4.3,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Thomas, Martha",
    rating: 3.8,
    percent: "67%",
    difficulty: 3.9,
  },
  {
    instructor: "Howard, Marc",
    rating: 4.1,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Swers, Michele",
    rating: 4.2,
    percent: "96%",
    difficulty: 3,
  },
  {
    instructor: "Bailey, Michael",
    rating: 4.2,
    percent: "80%",
    difficulty: 3.3,
  },
  {
    instructor: "Kroenig, Matthew",
    rating: 3.8,
    percent: "78%",
    difficulty: 2.8,
  },
  {
    instructor: "Slaski, Alexander",
    rating: 2.4,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Iroulo, Lynda",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Grimm, Elizabeth",
    rating: 5,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "McMorrow, Marilyn",
    rating: 2.9,
    percent: "54%",
    difficulty: 3.4,
  },
  {
    instructor: "Girod, Desha",
    rating: 4.9,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Simmons, Joel",
    rating: 3.1,
    percent: "74%",
    difficulty: 3.9,
  },
  {
    instructor: "Kapiszewski, Diana",
    rating: 4.3,
    percent: "84%",
    difficulty: 4.1,
  },
  {
    instructor: "Howard, Lise",
    rating: 3.9,
    percent: "78%",
    difficulty: 3.2,
  },
  {
    instructor: "White, J.",
    rating: 3.7,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Bennett, Andrew",
    rating: 3.4,
    percent: "53%",
    difficulty: 3.3,
  },
  {
    instructor: "Kupchan, Charles",
    rating: 4.1,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Glass, James",
    rating: 3.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Birdsall, Judd",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Gibson, Andrew",
    rating: 3.5,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "McSweeney, Patrick",
    rating: 4,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Cammisa, Anne",
    rating: 5,
    percent: "50%",
    difficulty: 3,
  },
  {
    instructor: "Kamrava, Mehran",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Brumberg, Daniel",
    rating: 1.9,
    percent: "0%",
    difficulty: 3.3,
  },
  {
    instructor: "King, Stephen",
    rating: 2.8,
    percent: "84%",
    difficulty: 1.7,
  },
  {
    instructor: "Lord, Jeffrey",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Lotrionte, Catherine",
    rating: 3.9,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Mitchell, Joshua",
    rating: 3.3,
    percent: "56%",
    difficulty: 3.1,
  },
  {
    instructor: "Richardson, Mark",
    rating: 2.8,
    percent: "25%",
    difficulty: 4,
  },
  {
    instructor: "Scott, Jamil",
    rating: 4,
    percent: "75%",
    difficulty: 1.7,
  },
  {
    instructor: "Grant, Daniel",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Oakley, Alysson",
    rating: 2,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Skendaj, Elton",
    rating: 4.9,
    percent: "N/A",
    difficulty: 1.8,
  },
  {
    instructor: "Joseph, Brian",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Fischer, Jeffrey",
    rating: 3.2,
    percent: "N/A",
    difficulty: 4.1,
  },
  {
    instructor: "King, Charles",
    rating: 4.2,
    percent: "77%",
    difficulty: 3.5,
  },
  {
    instructor: "Shah, Aqil",
    rating: 4.7,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Collin, Katherine",
    rating: 5,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Edelstein, Lauren",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Stephens, Nate",
    rating: 3.4,
    percent: "65%",
    difficulty: 3.7,
  },
  {
    instructor: "Stanley, Elizabeth",
    rating: 4.7,
    percent: "N/A",
    difficulty: 2.7,
  },
  {
    instructor: "Orellana, Ayse",
    rating: 3.2,
    percent: "0%",
    difficulty: 3.1,
  },
  {
    instructor: "Inman, Molly",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Balcells, Laia",
    rating: 5,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Hall, William",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Ross, Lynn",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Cherniss, Joshua",
    rating: 3.8,
    percent: "76%",
    difficulty: 3.2,
  },
  {
    instructor: "Williams, Aaron",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Brown, Nadia",
    rating: 4,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Nooruddin, Irfan",
    rating: 1.9,
    percent: "18%",
    difficulty: 4.3,
  },
  {
    instructor: "McNamara, Kathleen",
    rating: 4.2,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Arend, Anthony",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Stimson, Shannon",
    rating: 3,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Eich, Stefan",
    rating: 4.1,
    percent: "84%",
    difficulty: 3,
  },
  {
    instructor: "Lamb, Ismini",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Cobb, Nathan",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Rappaport, Steven",
    rating: 3.2,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "Kunz, Lauren",
    rating: 2.5,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Zhang, Yili",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Alrimawi, Intima",
    rating: 4.2,
    percent: "89%",
    difficulty: 3.5,
  },
  {
    instructor: "Friedland, Robert",
    rating: 3.9,
    percent: "67%",
    difficulty: 2.8,
  },
  {
    instructor: "Riley, Joan",
    rating: 4.9,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Nahabedian, Anissa",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Coleman, Susan",
    rating: 4.6,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Leland, Kari",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Jibrin, Janis",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Suh, Ryung",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Horak, Bernard",
    rating: 4.7,
    percent: "100%",
    difficulty: 1.6,
  },
  {
    instructor: "Huang, Sean",
    rating: 4.5,
    percent: "88%",
    difficulty: 2.2,
  },
  {
    instructor: "Kraemer, John",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Kim, Daniel",
    rating: 3.6,
    percent: "91%",
    difficulty: 2.3,
  },
  {
    instructor: "Downing, Gregory",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Gollu, Gultekin",
    rating: 4,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Chaudhry, Saad",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Myers, Adam",
    rating: 3.7,
    percent: "N/A",
    difficulty: 3.6,
  },
  {
    instructor: "Chentsova Dutton, Yulia",
    rating: 3.6,
    percent: "80%",
    difficulty: 2.9,
  },
  {
    instructor: "Grayson, Sara",
    rating: 3.7,
    percent: "70%",
    difficulty: 2.4,
  },
  {
    instructor: "Orr, Meital",
    rating: 4.5,
    percent: "90%",
    difficulty: 3.6,
  },
  {
    instructor: "Abi-Mershed, Osama",
    rating: 3.8,
    percent: "43%",
    difficulty: 3.3,
  },
  {
    instructor: "Loza, Mireya",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "McCann, Bryan",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Pinkard, Susan",
    rating: 3.4,
    percent: "75%",
    difficulty: 2.9,
  },
  {
    instructor: "Roshwald, Aviel",
    rating: 3.6,
    percent: "75%",
    difficulty: 3.2,
  },
  {
    instructor: "Burnham, Jakob",
    rating: 3,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Games, Alison",
    rating: 3.5,
    percent: "78%",
    difficulty: 4.1,
  },
  {
    instructor: "Wall, Michael",
    rating: 3.9,
    percent: "97%",
    difficulty: 2.8,
  },
  {
    instructor: "Degroot, Dagomar",
    rating: 4.3,
    percent: "84%",
    difficulty: 3.2,
  },
  {
    instructor: "Gao, Yuan",
    rating: 2.8,
    percent: "N/A",
    difficulty: 3.4,
  },
  {
    instructor: "Shedel, James",
    rating: 2.6,
    percent: "39%",
    difficulty: 3.7,
  },
  {
    instructor: "Astarita, Tommaso",
    rating: 4,
    percent: "66%",
    difficulty: 3.7,
  },
  {
    instructor: "Tutino, John",
    rating: 2.4,
    percent: "30%",
    difficulty: 3.9,
  },
  {
    instructor: "Agoston, Gabor",
    rating: 3.2,
    percent: "63%",
    difficulty: 2.9,
  },
  {
    instructor: "Sbaiti, Nadya",
    rating: 3.6,
    percent: "N/A",
    difficulty: 4.3,
  },
  {
    instructor: "Afinogenov, Gregory",
    rating: 3.6,
    percent: "72%",
    difficulty: 3.1,
  },
  {
    instructor: "Stolarski, Christopher",
    rating: 4,
    percent: "83%",
    difficulty: 3.5,
  },
  {
    instructor: "Ray, Jonathan",
    rating: 4.2,
    percent: "87%",
    difficulty: 3,
  },
  {
    instructor: "Zimmers, Stefan",
    rating: 4,
    percent: "77%",
    difficulty: 3.5,
  },
  {
    instructor: "Kolla, Edward",
    rating: 3.1,
    percent: "50%",
    difficulty: 3.9,
  },
  {
    instructor: "Higuchi, Toshihiro",
    rating: 4.5,
    percent: "91%",
    difficulty: 3.3,
  },
  {
    instructor: "Jackson, Maurice",
    rating: 2.2,
    percent: "25%",
    difficulty: 3.5,
  },
  {
    instructor: "Kazin, Michael",
    rating: 4.3,
    percent: "82%",
    difficulty: 3.2,
  },
  {
    instructor: "Douma, Michael",
    rating: 1,
    percent: "67%",
    difficulty: 4,
  },
  {
    instructor: "Collins, James",
    rating: 3.8,
    percent: "84%",
    difficulty: 3.6,
  },
  {
    instructor: "Gannon, Darragh",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Langer, Erick",
    rating: 3.3,
    percent: "90%",
    difficulty: 3.2,
  },
  {
    instructor: "Cross, Elizabeth",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Chakravarti, Ananya",
    rating: 3,
    percent: "43%",
    difficulty: 4,
  },
  {
    instructor: "McKittrick, Meredith",
    rating: 4.1,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Benton-Cohen, Katherine",
    rating: 4,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Leonard, Amy",
    rating: 4.6,
    percent: "85%",
    difficulty: 3.3,
  },
  {
    instructor: "Manning, Chandra",
    rating: 3.7,
    percent: "80%",
    difficulty: 4.3,
  },
  {
    instructor: "Walther, Karine",
    rating: 4.5,
    percent: "100%",
    difficulty: 5,
  },
  {
    instructor: "Millward, James",
    rating: 3.7,
    percent: "43%",
    difficulty: 3.4,
  },
  {
    instructor: "Rothman, Adam",
    rating: 4.4,
    percent: "93%",
    difficulty: 2.8,
  },
  {
    instructor: "Aksakal, Mustafa",
    rating: 4.5,
    percent: "93%",
    difficulty: 3.4,
  },
  {
    instructor: "David-Fox, Michael",
    rating: 3.5,
    percent: "89%",
    difficulty: 3.5,
  },
  {
    instructor: "Nelson, Theodore",
    rating: 4.6,
    percent: "95%",
    difficulty: 4,
  },
  {
    instructor: "Lee, Andrew",
    rating: 4.7,
    percent: "95%",
    difficulty: 1.4,
  },
  {
    instructor: "Garman, Joseph",
    rating: 3.5,
    percent: "48%",
    difficulty: 4,
  },
  {
    instructor: "Cohen-Derr, Erika",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Day, Carol",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "LaRocque, Jeannine",
    rating: 5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Campbell, Lee",
    rating: 2.3,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Partridge, John",
    rating: 4.4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Furlong, Mary",
    rating: 4.3,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Watson, Lewis",
    rating: 3.5,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Barr, Rachel",
    rating: 3.7,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Marsh, Abigail",
    rating: 4.6,
    percent: "87%",
    difficulty: 3.3,
  },
  {
    instructor: "Cline, Erin",
    rating: 4.7,
    percent: "97%",
    difficulty: 2.2,
  },
  {
    instructor: "Fields, Corey",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Ciabattoni, Francesco",
    rating: 4.4,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Withy, Katherine",
    rating: 4.1,
    percent: "78%",
    difficulty: 3.7,
  },
  {
    instructor: "Evans, Christine",
    rating: 5,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Lu-Adler, Huaping",
    rating: 3.4,
    percent: "72%",
    difficulty: 3.8,
  },
  {
    instructor: "Heck, Paul",
    rating: 3.7,
    percent: "34%",
    difficulty: 3.6,
  },
  {
    instructor: "Elie, Paul",
    rating: 4.5,
    percent: "94%",
    difficulty: 3.6,
  },
  {
    instructor: "Kessler, Michael",
    rating: 3.6,
    percent: "50%",
    difficulty: 3.1,
  },
  {
    instructor: "Ryan, Michael G.",
    rating: 5,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Stilwell, Robynn",
    rating: 3.5,
    percent: "67%",
    difficulty: 3.1,
  },
  {
    instructor: "Cook, Bernard",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Giordano, Mark",
    rating: 4.6,
    percent: "95%",
    difficulty: 1.9,
  },
  {
    instructor: "Berlinerblau, Jacques",
    rating: 4,
    percent: "70%",
    difficulty: 3.8,
  },
  {
    instructor: "Veeraraghavan, Rajesh",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Voeten, Erik",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Marshall, Katherine",
    rating: 3.6,
    percent: "67%",
    difficulty: 2.7,
  },
  {
    instructor: "Ungar, Sanford",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Hrebenak, Keith",
    rating: 4.2,
    percent: "100%",
    difficulty: 4.5,
  },
  {
    instructor: "Sommer, Anna",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Khoja-Moolji, Shenila",
    rating: 4.8,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Garrett, Amanda",
    rating: 5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Hill, Brendan",
    rating: 4.6,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Brown-Fleming, Suzanne",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Ebenbach, David",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Soltes, Ori",
    rating: 4.1,
    percent: "75%",
    difficulty: 2,
  },
  {
    instructor: "Wagner, Christian",
    rating: 3,
    percent: "58%",
    difficulty: 2.8,
  },
  {
    instructor: "Craig, Ryann",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Pletka, Danielle",
    rating: 3.7,
    percent: "84%",
    difficulty: 3.3,
  },
  {
    instructor: "Desbois, Patrick",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Ware, Jacob",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Lincoln, Jonathan",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Patel, Parina",
    rating: 3.7,
    percent: "74%",
    difficulty: 2.7,
  },
  {
    instructor: "Lawler, Brian",
    rating: 1.7,
    percent: "0%",
    difficulty: 3.1,
  },
  {
    instructor: "Bodine, Barbara",
    rating: 5,
    percent: "100%",
    difficulty: 5,
  },
  {
    instructor: "Ross, Dennis",
    rating: 3.7,
    percent: "34%",
    difficulty: 2.5,
  },
  {
    instructor: "Schiwietz, Christine",
    rating: 4.1,
    percent: "N/A",
    difficulty: 1.8,
  },
  {
    instructor: "Boesen, Elizabeth",
    rating: 1,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Kelly, Edward",
    rating: 3.8,
    percent: "58%",
    difficulty: 3.2,
  },
  {
    instructor: "Neal, David",
    rating: 3.5,
    percent: "60%",
    difficulty: 3.7,
  },
  {
    instructor: "Kennedy, Joseph",
    rating: 3,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Donato, Katherine",
    rating: 4.5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Miller, Sarah",
    rating: 4.6,
    percent: "N/A",
    difficulty: 3.4,
  },
  {
    instructor: "Gordon, John",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Pollack, Kenneth",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Joe, Wesley",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Al Azm, Amr",
    rating: 4.6,
    percent: "75%",
    difficulty: 2.5,
  },
  {
    instructor: "Pierce, Albert",
    rating: 5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Nagel, Robert",
    rating: 3,
    percent: "40%",
    difficulty: 3.1,
  },
  {
    instructor: "Strzok, Peter",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Hurley, Erin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Wheat, Treston",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Rosenberg, Chuck",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Kauppi, Mark",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Chandra, Uday",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Melucci, Donatella",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Tosi, Giuseppe",
    rating: 3.9,
    percent: "80%",
    difficulty: 2.1,
  },
  {
    instructor: "Musti, Fulvia",
    rating: 4.1,
    percent: "91%",
    difficulty: 2.5,
  },
  {
    instructor: "Cicali, Gianni",
    rating: 4.3,
    percent: "80%",
    difficulty: 2.2,
  },
  {
    instructor: "Sato, Kumi",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Omori, Motoko",
    rating: 4.9,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Mori, Yoshiko",
    rating: 4.2,
    percent: "84%",
    difficulty: 3.8,
  },
  {
    instructor: "Doak, Kevin",
    rating: 4.1,
    percent: "96%",
    difficulty: 3.1,
  },
  {
    instructor: "Schaefer, Daniel",
    rating: 4.1,
    percent: "100%",
    difficulty: 1.6,
  },
  {
    instructor: "Oldenburg, Ann",
    rating: 4.8,
    percent: "94%",
    difficulty: 2.6,
  },
  {
    instructor: "Ahmed, Saeed",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Albergo, Paul",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Jenkins, Anthony",
    rating: 5,
    percent: "100%",
    difficulty: 1.2,
  },
  {
    instructor: "Atashi, Elham",
    rating: 4.2,
    percent: "89%",
    difficulty: 3.2,
  },
  {
    instructor: "Huisman Lubreski, Kimberly",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "McCarthy, Eli",
    rating: 3.7,
    percent: "0%",
    difficulty: 2,
  },
  {
    instructor: "Jafari, Sheherazade",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Kritz, Brian",
    rating: 4.4,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Ensor, Marisa",
    rating: 2,
    percent: "0%",
    difficulty: 1,
  },
  {
    instructor: "Mun, Bokyung",
    rating: 4.3,
    percent: "85%",
    difficulty: 2.4,
  },
  {
    instructor: "Lee, Aimee",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Choi, Min",
    rating: 4.1,
    percent: "72%",
    difficulty: 1.3,
  },
  {
    instructor: "Cho, Francisca",
    rating: 3.8,
    percent: "58%",
    difficulty: 2.9,
  },
  {
    instructor: "Barr, Nancy",
    rating: 4.7,
    percent: "67%",
    difficulty: 3.3,
  },
  {
    instructor: "Whelan, Kathy",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Benson, James",
    rating: 4.6,
    percent: "N/A",
    difficulty: 2.1,
  },
  {
    instructor: "Healy, Kevin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Massey, William",
    rating: 2,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Nagel, Walter",
    rating: 2.9,
    percent: "72%",
    difficulty: 2.9,
  },
  {
    instructor: "Cohen, Adam",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Solomon, Robin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Berman, Kenneth",
    rating: 3,
    percent: "94%",
    difficulty: 2.8,
  },
  {
    instructor: "Scopino, Gregory",
    rating: 1,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Kaufman, Beth",
    rating: 4.6,
    percent: "67%",
    difficulty: 2.2,
  },
  {
    instructor: "Wang, James",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "McManus, Brian",
    rating: 3.2,
    percent: "60%",
    difficulty: 3.8,
  },
  {
    instructor: "Johnson, Steven",
    rating: 4.9,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Kirk, David",
    rating: 3,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Evans, Marianne",
    rating: 3,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Sanders, Michael",
    rating: 2.2,
    percent: "N/A",
    difficulty: 3.6,
  },
  {
    instructor: "Bates, John",
    rating: 3.3,
    percent: "N/A",
    difficulty: 4.1,
  },
  {
    instructor: "Krishnakumar, Anita",
    rating: 4.8,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Nager, Glen",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Pitt, Jonathan",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Foglia, Jonathon",
    rating: 1,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Rutzen, Douglas",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Wallace, Don",
    rating: 4.2,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Stewart, David",
    rating: 4.4,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Hempling, Scott",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Mitchell, Brent",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Gelpern, Anna",
    rating: 4.4,
    percent: "N/A",
    difficulty: 3.1,
  },
  {
    instructor: "Heinzerling, Lisa",
    rating: 4.6,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Thompson, Robert",
    rating: 3,
    percent: "N/A",
    difficulty: 2.9,
  },
  {
    instructor: "Diamond, Michael",
    rating: 4.3,
    percent: "100%",
    difficulty: 1.6,
  },
  {
    instructor: "Davidow, Charles",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Langevoort, Donald",
    rating: 5,
    percent: "100%",
    difficulty: 3.7,
  },
  {
    instructor: "Feinerman, James",
    rating: 3.2,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "Newman, Derek",
    rating: 4.8,
    percent: "100%",
    difficulty: 1.8,
  },
  {
    instructor: "Levitin, Adam",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Schneider, Jeffrey",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Adler, William",
    rating: 3.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Hunter, Nan",
    rating: 3.9,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Devlin, Alan",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Huffman, Robert",
    rating: 4.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Menezes, Mark",
    rating: 3.7,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Hyman, David",
    rating: 3.9,
    percent: "0%",
    difficulty: 2.3,
  },
  {
    instructor: "Ahmed, Usman",
    rating: 4,
    percent: "80%",
    difficulty: 3,
  },
  {
    instructor: "Buzbee, William",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Collins, Wayne",
    rating: 1.5,
    percent: "0%",
    difficulty: 5,
  },
  {
    instructor: "Goldman, Julie",
    rating: 4.8,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Diamond, Nicholas",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Beatty-Arthur, Sherri",
    rating: 4.6,
    percent: "N/A",
    difficulty: 1.7,
  },
  {
    instructor: "Fogel, David",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Blaze, Matt",
    rating: 4,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Abu-Odeh, Lama",
    rating: 2.8,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Teitelbaum, Joshua",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Hagan, Sean",
    rating: 5,
    percent: "100%",
    difficulty: 1.6,
  },
  {
    instructor: "Hillman, Jennifer",
    rating: 4.2,
    percent: "100%",
    difficulty: 2.1,
  },
  {
    instructor: "Reich, Jennifer",
    rating: 3.9,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Cole, David",
    rating: 3.9,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Goodwin, Michele",
    rating: 2.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Summers, Nicole",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Shaffer, Gregory",
    rating: 4.6,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Everly, George",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Brummer, Chris",
    rating: 3,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Cabrera, Oscar",
    rating: 4,
    percent: "N/A",
    difficulty: 3.2,
  },
  {
    instructor: "Serrano, Silvia",
    rating: 2.5,
    percent: "84%",
    difficulty: 3.5,
  },
  {
    instructor: "McLeod, Allegra",
    rating: 4.4,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Luban, David",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Hammond, Emily",
    rating: 4.1,
    percent: "82%",
    difficulty: 3.2,
  },
  {
    instructor: "Lopez, Christy",
    rating: 4.3,
    percent: "88%",
    difficulty: 2,
  },
  {
    instructor: "Navarrete, Andres",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Baer, Gregory",
    rating: 4.3,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "McLaughlin, James",
    rating: 2.5,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Koplow, David",
    rating: 4.5,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Freeman, Alexa",
    rating: 4.6,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Buzzard, Greg",
    rating: 4.9,
    percent: "84%",
    difficulty: 3.2,
  },
  {
    instructor: "Ross, Ronald",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Laing, Andrew",
    rating: 3.8,
    percent: "56%",
    difficulty: 2.6,
  },
  {
    instructor: "Bloche, Gregg",
    rating: 2.3,
    percent: "0%",
    difficulty: 2.6,
  },
  {
    instructor: "Carter, Phillip",
    rating: 3.7,
    percent: "N/A",
    difficulty: 1.7,
  },
  {
    instructor: "Peller, Gary",
    rating: 3.4,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "Lopez, James",
    rating: 4.9,
    percent: "100%",
    difficulty: 1.8,
  },
  {
    instructor: "Lynn, David",
    rating: 5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Costa, Christopher",
    rating: 1,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Bowman, John",
    rating: 1.8,
    percent: "N/A",
    difficulty: 4.7,
  },
  {
    instructor: "Nussdorf, Benjamin",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Horowitz, Stephen",
    rating: 3.4,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Cheng, Benjamin",
    rating: 4,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Levy, Joshua",
    rating: 2.4,
    percent: "N/A",
    difficulty: 4.3,
  },
  {
    instructor: "Weinberg, Robert",
    rating: 1.6,
    percent: "0%",
    difficulty: 3.7,
  },
  {
    instructor: "West, Robin",
    rating: 3.8,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Dwyer, Mary",
    rating: 3.8,
    percent: "N/A",
    difficulty: 4.2,
  },
  {
    instructor: "Jackson, Molly",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Brasure, Ian",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Monahan, John",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Lewis, Jeffrey",
    rating: 3,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Mezey, Naomi",
    rating: 4.4,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Thomas, John",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Micallef, Joseph",
    rating: 4.7,
    percent: "100%",
    difficulty: 1.7,
  },
  {
    instructor: "Cohen, Julie",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Snyder, Brad",
    rating: 2.8,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Grinberg, Itai",
    rating: 2.8,
    percent: "0%",
    difficulty: 4.8,
  },
  {
    instructor: "Gostin, Lawrence",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Ryan, Susan",
    rating: 4.5,
    percent: "67%",
    difficulty: 3.6,
  },
  {
    instructor: "Watal, Jayashree",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Becker, Julie",
    rating: 1.4,
    percent: "N/A",
    difficulty: 2.6,
  },
  {
    instructor: "Bloom, Michael",
    rating: 5,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Davidson, Christopher",
    rating: 3.8,
    percent: "63%",
    difficulty: 4,
  },
  {
    instructor: "Jonas, David",
    rating: 2.6,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Gilmore, Scott",
    rating: 4.7,
    percent: "86%",
    difficulty: 2.2,
  },
  {
    instructor: "Hoffman, Craig",
    rating: 4.4,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Walker, George",
    rating: 1.7,
    percent: "N/A",
    difficulty: 4.5,
  },
  {
    instructor: "Smith, Michael",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Ross, Elizabeth",
    rating: 3.9,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Kelly, Ryan",
    rating: 4.3,
    percent: "N/A",
    difficulty: 1.3,
  },
  {
    instructor: "Cohen, Arthur",
    rating: 4.4,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Decker, Bruce",
    rating: 2,
    percent: "25%",
    difficulty: 2.8,
  },
  {
    instructor: "Carlson, Sara",
    rating: 2.8,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Moon, Daniel",
    rating: 4.6,
    percent: "N/A",
    difficulty: 3.3,
  },
  {
    instructor: "Nolan, Michael",
    rating: 2.8,
    percent: "100%",
    difficulty: 4.2,
  },
  {
    instructor: "Benz, Stephen",
    rating: 4.9,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Richman, Lisa",
    rating: 4.5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Oliver, John",
    rating: 4.5,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Smith, Russell",
    rating: 4.4,
    percent: "N/A",
    difficulty: 1.6,
  },
  {
    instructor: "Arlyck, Kevin",
    rating: 5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Francois, Aderson",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Vladeck, David",
    rating: 3.9,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Claussen, Kathleen",
    rating: 5,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Klass, Gregory",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Spann, Girardeau",
    rating: 3.7,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Super, David",
    rating: 4.5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Gottesman, Michael",
    rating: 3.8,
    percent: "88%",
    difficulty: 3.3,
  },
  {
    instructor: "Lawrence, Frederick",
    rating: 4.9,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Bloch, Susan",
    rating: 3.6,
    percent: "50%",
    difficulty: 2.7,
  },
  {
    instructor: "Donohue, Laura",
    rating: 3.2,
    percent: "0%",
    difficulty: 4.1,
  },
  {
    instructor: "Cashin, Sheryll",
    rating: 2.3,
    percent: "72%",
    difficulty: 2.9,
  },
  {
    instructor: "Lederman, Martin",
    rating: 2.2,
    percent: "0%",
    difficulty: 3.5,
  },
  {
    instructor: "Creighton, Sara",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Griffin, Amy",
    rating: 3.7,
    percent: "100%",
    difficulty: 4.1,
  },
  {
    instructor: "Wherry, Jessica",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Cedrone, Michael",
    rating: 5,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Byrne, John",
    rating: 3.3,
    percent: "50%",
    difficulty: 3.5,
  },
  {
    instructor: "Rothstein, Paul",
    rating: 3.5,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Mikhail, John",
    rating: 3.5,
    percent: "67%",
    difficulty: 3.9,
  },
  {
    instructor: "Rogers, Brishen",
    rating: 3.8,
    percent: "N/A",
    difficulty: 3.2,
  },
  {
    instructor: "Gunja, Mushtaq",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Liu, Michelle",
    rating: 4,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Cooper, Catherine",
    rating: 2.8,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Wolfman, Brian",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Albert, Janet",
    rating: 3.9,
    percent: "N/A",
    difficulty: 3.3,
  },
  {
    instructor: "Cook, Anthony",
    rating: 3.6,
    percent: "67%",
    difficulty: 2.9,
  },
  {
    instructor: "Stebbins, Ann",
    rating: 4.5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Pollack, Barry",
    rating: 1,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Lopez, Jonathan",
    rating: 4.2,
    percent: "88%",
    difficulty: 2.4,
  },
  {
    instructor: "Rogal, Lauren",
    rating: 2,
    percent: "0%",
    difficulty: 5,
  },
  {
    instructor: "Butler, Paul",
    rating: 2.7,
    percent: "34%",
    difficulty: 3.5,
  },
  {
    instructor: "Newton, Brent",
    rating: 4.8,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Dewey, Elizabeth",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Feldman, Heidi",
    rating: 3.9,
    percent: "N/A",
    difficulty: 2.7,
  },
  {
    instructor: "Riley, Patricia",
    rating: 5,
    percent: "100%",
    difficulty: 1.6,
  },
  {
    instructor: "Hills, Stephen",
    rating: 2.5,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Holland, Emily",
    rating: 4,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Howell, Charisma",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Walsh, Kelly",
    rating: 3.7,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Bright, Stephen",
    rating: 2.9,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Tiscione, Kristen",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2.7,
  },
  {
    instructor: "White, Alexander",
    rating: 1.5,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Carroll, Deborah",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Iyer, Sunita",
    rating: 4.3,
    percent: "92%",
    difficulty: 2.7,
  },
  {
    instructor: "Quinn, Sean",
    rating: 4.2,
    percent: "60%",
    difficulty: 2.1,
  },
  {
    instructor: "Parker, Morris",
    rating: 3.5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Blanchard, Alexander",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Choksi, Rachit",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Hopwood, Shon",
    rating: 2,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Ramos, Valerie",
    rating: 4.4,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "McCord, Mary",
    rating: 1.8,
    percent: "15%",
    difficulty: 3.2,
  },
  {
    instructor: "McCarty, Patrick",
    rating: 2.8,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Pillard, Nina",
    rating: 4.7,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Morin, Robert",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Fisher, Gerald",
    rating: 3.6,
    percent: "N/A",
    difficulty: 2.6,
  },
  {
    instructor: "Rostain, Tanina",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2.7,
  },
  {
    instructor: "Nourse, Victoria",
    rating: 2.5,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Tucker, Emily",
    rating: 3.5,
    percent: "50%",
    difficulty: 3.2,
  },
  {
    instructor: "Tsoukala, Philomila",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Donahoe, Diana",
    rating: 2.9,
    percent: "N/A",
    difficulty: 3.4,
  },
  {
    instructor: "George, Janel",
    rating: 1,
    percent: "0%",
    difficulty: 1,
  },
  {
    instructor: "Vazquez, Carlos",
    rating: 5,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "DeLaurentis, Frances",
    rating: 2.9,
    percent: "67%",
    difficulty: 3.4,
  },
  {
    instructor: "Regan, Milton",
    rating: 3,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "McIntosh, Sarah",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Smith, Paul",
    rating: 1.8,
    percent: "N/A",
    difficulty: 3.6,
  },
  {
    instructor: "Barnett, Randy",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Chang, Steve",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Roth, Christopher",
    rating: 4.6,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Curry, John",
    rating: 4.3,
    percent: "N/A",
    difficulty: 1.6,
  },
  {
    instructor: "Chafetz, Josh",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Roe, Richard",
    rating: 4.2,
    percent: "50%",
    difficulty: 3.3,
  },
  {
    instructor: "Bordone, Robert",
    rating: 3.7,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Glover, Kyle",
    rating: 3.1,
    percent: "25%",
    difficulty: 4.4,
  },
  {
    instructor: "Altman, Stephen",
    rating: 5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Walker, Juan",
    rating: 3.8,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Terry, Michael",
    rating: 4.7,
    percent: "N/A",
    difficulty: 2.9,
  },
  {
    instructor: "Sharpe, Ronald",
    rating: 4.8,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Teicher, Stuart",
    rating: 4.3,
    percent: "80%",
    difficulty: 4.1,
  },
  {
    instructor: "Dorsainvil, Dolores",
    rating: 3,
    percent: "50%",
    difficulty: 4.7,
  },
  {
    instructor: "Clement, Paul",
    rating: 4.8,
    percent: "100%",
    difficulty: 1.7,
  },
  {
    instructor: "Edelman, Peter",
    rating: 2.7,
    percent: "0%",
    difficulty: 3.2,
  },
  {
    instructor: "Brown, Dorothy",
    rating: 4.2,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Stumberg, Robert",
    rating: 1,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Copacino, John",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Johnson, Vida",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Ross, Susan",
    rating: 4.4,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Sunder, Madhavi",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Colangelo, Sara",
    rating: 4,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Yellin, David",
    rating: 2.6,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Henry, Ralph",
    rating: 3,
    percent: "N/A",
    difficulty: 3.9,
  },
  {
    instructor: "Debelius, Margaret",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Vovides, Yianna",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Alexander, Bryan",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Skallerup-Bessette, Lee",
    rating: 4.7,
    percent: "N/A",
    difficulty: 1.8,
  },
  {
    instructor: "Renzi, Doireann",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Davis, Daniel",
    rating: 4.5,
    percent: "N/A",
    difficulty: 1.7,
  },
  {
    instructor: "Bower, Beth",
    rating: 5,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Collins, Kathleen",
    rating: 3.7,
    percent: "50%",
    difficulty: 1.8,
  },
  {
    instructor: "Taylor, J",
    rating: 3.5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Quirk, Rory",
    rating: 4.8,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Ruf, Frederick",
    rating: 3.7,
    percent: "80%",
    difficulty: 2.7,
  },
  {
    instructor: "Shook, John",
    rating: 2.5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Heller, Sophie",
    rating: 4.3,
    percent: "89%",
    difficulty: 2.5,
  },
  {
    instructor: "Gomez, Janet",
    rating: 4.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Collina, Sara",
    rating: 5,
    percent: "100%",
    difficulty: 2.1,
  },
  {
    instructor: "Moore, Casey",
    rating: 4.9,
    percent: "N/A",
    difficulty: 2.7,
  },
  {
    instructor: "Lemieux, Frederic",
    rating: 2.6,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Paasch, J",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Golden, Christian",
    rating: 3.2,
    percent: "100%",
    difficulty: 3.6,
  },
  {
    instructor: "Grant, Patricia",
    rating: 3.9,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Buckley, William",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Manuel, Paul",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Majeed, Ibrahim",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Harrell, Paula",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Lorenson, Sue",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Richardson, Travis",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Li, Xiang",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Zsiga, Elizabeth",
    rating: 3.5,
    percent: "34%",
    difficulty: 3.3,
  },
  {
    instructor: "Obiri-Yeboah, Michael",
    rating: 0,
    percent: "0%",
    difficulty: 0,
  },
  {
    instructor: "Fond, Marissa",
    rating: 4.6,
    percent: "89%",
    difficulty: 2.3,
  },
  {
    instructor: "Williams, Hannah",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Malone, Meg",
    rating: 4.3,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Zeldes, Amir",
    rating: 3.7,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Bryfonski, Lara",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Miller, Corey",
    rating: 3.5,
    percent: "34%",
    difficulty: 2,
  },
  {
    instructor: "Nycz, Jennifer",
    rating: 2.6,
    percent: "50%",
    difficulty: 4.4,
  },
  {
    instructor: "Campos, Hector",
    rating: 4.6,
    percent: "96%",
    difficulty: 3.5,
  },
  {
    instructor: "Portner, Paul",
    rating: 4,
    percent: "86%",
    difficulty: 3.2,
  },
  {
    instructor: "Tannen, Deborah",
    rating: 3.7,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Gordon, Cynthia",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Miller, Paul",
    rating: 3,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Shambaugh, George",
    rating: 3.8,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Gettig, Eric",
    rating: 4,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "McFarland, Kelly",
    rating: 3.5,
    percent: "75%",
    difficulty: 3.2,
  },
  {
    instructor: "Bailey, David",
    rating: 2.4,
    percent: "N/A",
    difficulty: 4.1,
  },
  {
    instructor: "Lewis, Joanna",
    rating: 3,
    percent: "0%",
    difficulty: 3.4,
  },
  {
    instructor: "King, Marcus",
    rating: 1,
    percent: "0%",
    difficulty: 5,
  },
  {
    instructor: "McManus, Matthew",
    rating: 4.4,
    percent: "90%",
    difficulty: 3.2,
  },
  {
    instructor: "Umberger, Doug",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "McCullough, Katherine",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Rogowsky, Robert",
    rating: 3.4,
    percent: "N/A",
    difficulty: 4.3,
  },
  {
    instructor: "Gallucci, Robert",
    rating: 4.9,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Vittori, Jodi",
    rating: 1,
    percent: "0%",
    difficulty: 5,
  },
  {
    instructor: "Helman, Joseph",
    rating: 5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Youakim, Claudia",
    rating: 4.4,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Wallis, David",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Negroponte, Diana",
    rating: 3.5,
    percent: "50%",
    difficulty: 3,
  },
  {
    instructor: "Friedman, Bruce",
    rating: 2.9,
    percent: "50%",
    difficulty: 4.4,
  },
  {
    instructor: "Bergman, Michael",
    rating: 3.5,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Yost, Casimir",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Edelstein, David",
    rating: 4.6,
    percent: "89%",
    difficulty: 3.3,
  },
  {
    instructor: "Ryan, Michael",
    rating: 5,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Ammerman, Allen",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Tang, Wei",
    rating: 2.5,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Guerrero, Jose",
    rating: 3.6,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Robison, Greg",
    rating: 3.4,
    percent: "0%",
    difficulty: 2.8,
  },
  {
    instructor: "Yip, Jeremy",
    rating: 4.4,
    percent: "89%",
    difficulty: 2.7,
  },
  {
    instructor: "Williams, Evelyn",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Bradley, Melissa",
    rating: 4,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Gamble, Jay",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Al-Momen, Serene",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Cong, Ziwei",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Lee, Kelly",
    rating: 4.5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Thompson, Debora",
    rating: 4,
    percent: "50%",
    difficulty: 2.1,
  },
  {
    instructor: "Towns, Marlene",
    rating: 1.5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Lynn, James",
    rating: 4.1,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Conway, Martin",
    rating: 4.5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Blair, Sean",
    rating: 4.2,
    percent: "81%",
    difficulty: 2.8,
  },
  {
    instructor: "Nategh, Emisa",
    rating: 4.5,
    percent: "95%",
    difficulty: 2.6,
  },
  {
    instructor: "Gharahbeigi, Sara",
    rating: 4.3,
    percent: "88%",
    difficulty: 2.5,
  },
  {
    instructor: "Meyer, Oded",
    rating: 4.3,
    percent: "91%",
    difficulty: 2.6,
  },
  {
    instructor: "Extejt, John",
    rating: 3.5,
    percent: "75%",
    difficulty: 3.3,
  },
  {
    instructor: "Gjoni, Leonard",
    rating: 4,
    percent: "N/A",
    difficulty: 2.6,
  },
  {
    instructor: "Rabbani, Tahseen",
    rating: 4,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Kurtz, Jeff",
    rating: 4,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Mehmetaj, Erblin",
    rating: 3.8,
    percent: "72%",
    difficulty: 2.9,
  },
  {
    instructor: "Meyer, Nicole",
    rating: 4.4,
    percent: "92%",
    difficulty: 2.8,
  },
  {
    instructor: "Kainen, Paul",
    rating: 2.7,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Meyer, Mark",
    rating: 2.7,
    percent: "20%",
    difficulty: 4,
  },
  {
    instructor: "Fan, Haitao",
    rating: 2.6,
    percent: "75%",
    difficulty: 2.8,
  },
  {
    instructor: "White, Tyler",
    rating: 3.8,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Raney, Michael",
    rating: 4.5,
    percent: "90%",
    difficulty: 2.7,
  },
  {
    instructor: "Dayaratna, Kevin",
    rating: 2.8,
    percent: "86%",
    difficulty: 3.5,
  },
  {
    instructor: "Rantou, Eleni",
    rating: 4.7,
    percent: "N/A",
    difficulty: 1.8,
  },
  {
    instructor: "Caraballo, David",
    rating: 4.3,
    percent: "42%",
    difficulty: 2.7,
  },
  {
    instructor: "Chang, Der-Chen",
    rating: 4.3,
    percent: "88%",
    difficulty: 2.1,
  },
  {
    instructor: "Engler, Hans",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Eller, Matthias",
    rating: 3,
    percent: "72%",
    difficulty: 3.9,
  },
  {
    instructor: "Leviyang, Sivan",
    rating: 4.6,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Valdivia, Nicolas",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Yashtini, Maryam",
    rating: 4.1,
    percent: "78%",
    difficulty: 2.8,
  },
  {
    instructor: "Monin, Philip",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Aram, Siamak",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Price, Stuart",
    rating: 2.5,
    percent: "50%",
    difficulty: 3,
  },
  {
    instructor: "Arab, Ali",
    rating: 3.6,
    percent: "50%",
    difficulty: 2.7,
  },
  {
    instructor: "Hammett, John",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Holt, Richard",
    rating: 4.8,
    percent: "86%",
    difficulty: 1.9,
  },
  {
    instructor: "Miller, David",
    rating: 4.4,
    percent: "50%",
    difficulty: 3.2,
  },
  {
    instructor: "Lin, Kenneth",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Cameron, Donna",
    rating: 3.8,
    percent: "88%",
    difficulty: 3.4,
  },
  {
    instructor: "Donnelly, Michael",
    rating: 3.9,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Cole, Michael",
    rating: 3.3,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Taylor, David",
    rating: 1.7,
    percent: "N/A",
    difficulty: 4.3,
  },
  {
    instructor: "Roberts, Stephanie",
    rating: 1.5,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Guarino, Thomas",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Kuhlman, Laura",
    rating: 5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Kim, Crystal",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Casey, John",
    rating: 3.1,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Jones, Shiloh",
    rating: 3.3,
    percent: "N/A",
    difficulty: 4.5,
  },
  {
    instructor: "Moore, Eileen",
    rating: 4.9,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Fisher, Tyler",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Carroll, Kevin",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Cohen, Jeffrey",
    rating: 3.2,
    percent: "N/A",
    difficulty: 3.2,
  },
  {
    instructor: "Jones, Allison",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Stewart, Colin",
    rating: 4.3,
    percent: "75%",
    difficulty: 3.4,
  },
  {
    instructor: "Schreiber, Jennifer",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Hicks, Daniel",
    rating: 3.9,
    percent: "N/A",
    difficulty: 2.6,
  },
  {
    instructor: "Pottash, Michael",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Krishnan, Lakshmi",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Spike, Jeffrey",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Langley, Julia",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Saunders, Pamela",
    rating: 3.7,
    percent: "75%",
    difficulty: 3.4,
  },
  {
    instructor: "Holman, Robert",
    rating: 4.6,
    percent: "75%",
    difficulty: 3.9,
  },
  {
    instructor: "Roberts, Allen",
    rating: 2.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Jamieson, Daniel",
    rating: 1.6,
    percent: "0%",
    difficulty: 4.4,
  },
  {
    instructor: "Li, Dongmei",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Blair, Taylor",
    rating: 4.2,
    percent: "74%",
    difficulty: 2.8,
  },
  {
    instructor: "Vasquez, Juan",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Morningstar, James",
    rating: 1,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Binkholder, Frederick",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Bratcher, Paul",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Murray, David",
    rating: 4.1,
    percent: "0%",
    difficulty: 2.4,
  },
  {
    instructor: "Del Donna, Anthony",
    rating: 4.6,
    percent: "89%",
    difficulty: 2.7,
  },
  {
    instructor: "Harbert, Benjamin",
    rating: 4.4,
    percent: "73%",
    difficulty: 3,
  },
  {
    instructor: "Simon, Carlos",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Hammond, Jay",
    rating: 3.6,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Schulman, David",
    rating: 3.5,
    percent: "N/A",
    difficulty: 4.8,
  },
  {
    instructor: "Weismann, Russell",
    rating: 4.4,
    percent: "92%",
    difficulty: 3.2,
  },
  {
    instructor: "Bracy, Michael",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Hashemi, Nader",
    rating: 1.4,
    percent: "50%",
    difficulty: 4.6,
  },
  {
    instructor: "Harris, Brent",
    rating: 4.8,
    percent: "86%",
    difficulty: 1,
  },
  {
    instructor: "Granitto, Margaret",
    rating: 4,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Bell, Sarah",
    rating: 3.8,
    percent: "N/A",
    difficulty: 4.4,
  },
  {
    instructor: "Oliver, Julie",
    rating: 2.2,
    percent: "N/A",
    difficulty: 1.7,
  },
  {
    instructor: "Davis, Diane",
    rating: 3.9,
    percent: "100%",
    difficulty: 3.9,
  },
  {
    instructor: "White, Krista",
    rating: 5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Potoka, Evelyn",
    rating: 4,
    percent: "100%",
    difficulty: 5,
  },
  {
    instructor: "Garcia, Rick",
    rating: 3.1,
    percent: "N/A",
    difficulty: 2.9,
  },
  {
    instructor: "Taylor, Carol",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Ashby, Jennifer",
    rating: 3,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Bultemeier, Kaye",
    rating: 3,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Robson, Beth",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Stewart, Amy",
    rating: 1,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Evans, Mara",
    rating: 2.9,
    percent: "54%",
    difficulty: 3.6,
  },
  {
    instructor: "Bradford, Heather",
    rating: 3.7,
    percent: "67%",
    difficulty: 3,
  },
  {
    instructor: "Frisvold, Melissa",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Burke, Bridget",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Lewis, Jade",
    rating: 4.9,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Gregory, Karen",
    rating: 2.2,
    percent: "0%",
    difficulty: 3.7,
  },
  {
    instructor: "CASTALDI, CINDY",
    rating: 1,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Brooks, Mirella",
    rating: 3.6,
    percent: "72%",
    difficulty: 3.2,
  },
  {
    instructor: "Estes, Tracy",
    rating: 2,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Chaplin, Lisa",
    rating: 3.5,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Kimble, David",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Wolfe, Jennifer",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Nadelson, Sandra",
    rating: 4,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Puhl, Jonathan",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Gallagher, Eva",
    rating: 2.8,
    percent: "34%",
    difficulty: 3.7,
  },
  {
    instructor: "Kim, Emmanuel",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Carney, Elissa",
    rating: 1.2,
    percent: "0%",
    difficulty: 4.6,
  },
  {
    instructor: "Ruth-Sahd, Lisa",
    rating: 3,
    percent: "50%",
    difficulty: 3.8,
  },
  {
    instructor: "Berta, Emily",
    rating: 4.5,
    percent: "75%",
    difficulty: 4.2,
  },
  {
    instructor: "Haffner, Gina",
    rating: 2,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "White, Susan",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Solis, Abbye",
    rating: 3.5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Powell, Tonya",
    rating: 2.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Cottrell, Damon",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Williams, Rhea",
    rating: 1,
    percent: "72%",
    difficulty: 3.7,
  },
  {
    instructor: "Farley, Cynthia",
    rating: 1,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Horton, Laura",
    rating: 4.5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Ogg-Gress, Jill",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Lange, Megan",
    rating: 3.4,
    percent: "60%",
    difficulty: 3.5,
  },
  {
    instructor: "Burnside, Chandra",
    rating: 5,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Stevenson, Rachel",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Marea, Christina",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Heitzler, Ella",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Kosko, Debra",
    rating: 3,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Haras, Mary",
    rating: 4.5,
    percent: "N/A",
    difficulty: 3.3,
  },
  {
    instructor: "Borbacs, Michael",
    rating: 3.5,
    percent: "75%",
    difficulty: 2.1,
  },
  {
    instructor: "Prifti, Erjona",
    rating: 2.3,
    percent: "44%",
    difficulty: 3.4,
  },
  {
    instructor: "Kaestner, Sonia",
    rating: 2.8,
    percent: "53%",
    difficulty: 2.3,
  },
  {
    instructor: "Frazier, Greg",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Kundu, Amrita",
    rating: 1.9,
    percent: "40%",
    difficulty: 4.4,
  },
  {
    instructor: "Montano, Bonnie",
    rating: 3.6,
    percent: "78%",
    difficulty: 2.9,
  },
  {
    instructor: "Iglarsh, Harvey",
    rating: 2.3,
    percent: "30%",
    difficulty: 3.4,
  },
  {
    instructor: "Elsaleiby, Aber",
    rating: 5,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Rossetti, Michael",
    rating: 4.3,
    percent: "N/A",
    difficulty: 1.9,
  },
  {
    instructor: "Dillon-Merrill, Robin",
    rating: 4,
    percent: "67%",
    difficulty: 3.5,
  },
  {
    instructor: "Babich, Volodymyr",
    rating: 2.5,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Jose, Victor",
    rating: 4.4,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Stroud, Jonathan",
    rating: 1,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Dasmohapatra, Sudipta",
    rating: 2,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Lyon, Gregory",
    rating: 3.4,
    percent: "80%",
    difficulty: 3,
  },
  {
    instructor: "Murphy, Mark",
    rating: 4.6,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Shope, David",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Keown, John",
    rating: 3.4,
    percent: "75%",
    difficulty: 2.6,
  },
  {
    instructor: "Kao, Sherry",
    rating: 4.5,
    percent: "100%",
    difficulty: 1.7,
  },
  {
    instructor: "Donley, Clark",
    rating: 5,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Greco, John",
    rating: 4.9,
    percent: "N/A",
    difficulty: 3.4,
  },
  {
    instructor: "Mulherin, Thomas",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Ver Eecke, Wilfried",
    rating: 2.9,
    percent: "34%",
    difficulty: 3.2,
  },
  {
    instructor: "Tuttle, Jacob",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Stripling, Matthew",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Aas, Sean",
    rating: 3.1,
    percent: "50%",
    difficulty: 3.4,
  },
  {
    instructor: "Jacob, Anjana",
    rating: 4.9,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Widerquist, Karl",
    rating: 3.8,
    percent: "84%",
    difficulty: 4.4,
  },
  {
    instructor: "Bishop, Laura",
    rating: 4.3,
    percent: "100%",
    difficulty: 1.7,
  },
  {
    instructor: "Kukla, Quill",
    rating: 3.8,
    percent: "82%",
    difficulty: 2.6,
  },
  {
    instructor: "Earl, Jacob",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Brick, Shannon",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Wojtkiewicz, Kate",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Carse, Alisa",
    rating: 3.3,
    percent: "84%",
    difficulty: 2.5,
  },
  {
    instructor: "Pratt, David",
    rating: 1.8,
    percent: "25%",
    difficulty: 3.6,
  },
  {
    instructor: "Williams, David",
    rating: 3.1,
    percent: "75%",
    difficulty: 3.6,
  },
  {
    instructor: "Olsen, James",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Farr, Jason",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Richardson, Henry",
    rating: 2.7,
    percent: "48%",
    difficulty: 3.5,
  },
  {
    instructor: "Torres, Christopher",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Watson, Ari",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Elzinga, Benjamin",
    rating: 3.4,
    percent: "55%",
    difficulty: 2.8,
  },
  {
    instructor: "Blattner, William",
    rating: 3.6,
    percent: "78%",
    difficulty: 3.6,
  },
  {
    instructor: "Jurkiewicz, Adam",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Lewis, Neil",
    rating: 3.2,
    percent: "67%",
    difficulty: 2.5,
  },
  {
    instructor: "Lindeman, David",
    rating: 4.3,
    percent: "90%",
    difficulty: 3.6,
  },
  {
    instructor: "Mattingly, James",
    rating: 2.9,
    percent: "31%",
    difficulty: 2.6,
  },
  {
    instructor: "Sullivan, William",
    rating: 3,
    percent: "75%",
    difficulty: 1,
  },
  {
    instructor: "Pinkard, Terry",
    rating: 4.2,
    percent: "80%",
    difficulty: 2.4,
  },
  {
    instructor: "Fleisher, William",
    rating: 4.8,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Sherman, Nancy",
    rating: 2.4,
    percent: "0%",
    difficulty: 3.8,
  },
  {
    instructor: "Johnson, Patrick",
    rating: 3.9,
    percent: "77%",
    difficulty: 3.5,
  },
  {
    instructor: "Currie, John",
    rating: 2,
    percent: "0%",
    difficulty: 3.6,
  },
  {
    instructor: "Doughty, Leanne",
    rating: 5,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Urbach, Jeffrey",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Liu, Amy",
    rating: 4,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Blair, Daniel",
    rating: 3.4,
    percent: "84%",
    difficulty: 2.7,
  },
  {
    instructor: "Freericks, James",
    rating: 2.5,
    percent: "88%",
    difficulty: 4.5,
  },
  {
    instructor: "Egolf, David",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Grigoryan, Hovhannes",
    rating: 4.5,
    percent: "100%",
    difficulty: 5,
  },
  {
    instructor: "Barbara, Paola",
    rating: 3.1,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Olmsted, Peter",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Liu, Kai",
    rating: 3.5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Frost, Holly",
    rating: 3.9,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Castilla, Marina",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Patterson, Richard",
    rating: 3.8,
    percent: "N/A",
    difficulty: 2.6,
  },
  {
    instructor: "Sadowska, Iwona",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Lucena, Sarah",
    rating: 5,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Marianos, Richard",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Westbrooks, Dennis",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Sharma, Rohin",
    rating: 3,
    percent: "50%",
    difficulty: 2.5,
  },
  {
    instructor: "Burke, Patrick",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Zulauf, Barry",
    rating: 1.5,
    percent: "0%",
    difficulty: 3.3,
  },
  {
    instructor: "Powers, Billy",
    rating: 4.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Noland, Brian",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Billy, Joseph",
    rating: 4.5,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Kavanagh, John",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Rubin, Scott",
    rating: 2.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Macklin, Renee",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Melo, Walcelio",
    rating: 3.4,
    percent: "75%",
    difficulty: 2,
  },
  {
    instructor: "Lewis, Angela",
    rating: 3.3,
    percent: "N/A",
    difficulty: 3.8,
  },
  {
    instructor: "Yayla, Ahmet",
    rating: 3,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Domenech, Edgar",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Mendoza, Cynthia",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Saunders, John",
    rating: 4.7,
    percent: "N/A",
    difficulty: 3.2,
  },
  {
    instructor: "Nguyen, Phuong",
    rating: 3,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Leusch-Carnaroli, Herbert",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "McCoy, Rhian",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Moriarty, Kathleen",
    rating: 4.5,
    percent: "N/A",
    difficulty: 1.5,
  },
  {
    instructor: "Pak, Charles",
    rating: 4.1,
    percent: "100%",
    difficulty: 1.4,
  },
  {
    instructor: "Azizian, Nazanin",
    rating: 1,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Applegate, Scott",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Brooks, Charles",
    rating: 3.4,
    percent: "N/A",
    difficulty: 2.9,
  },
  {
    instructor: "Sullivan, Clare",
    rating: 3.8,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Fazzini, Kate",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Baker, Sumera",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Sritapan, Vincent",
    rating: 5,
    percent: "100%",
    difficulty: 1.8,
  },
  {
    instructor: "Pelletier, Rosemarie",
    rating: 4.6,
    percent: "N/A",
    difficulty: 1.6,
  },
  {
    instructor: "Berkeley, Linda",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Julien, Anthony",
    rating: 3.8,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Gale, John",
    rating: 4.3,
    percent: "63%",
    difficulty: 2.3,
  },
  {
    instructor: "Schmidt, Scott",
    rating: 1.8,
    percent: "0%",
    difficulty: 2.5,
  },
  {
    instructor: "Stein, Elizabeth",
    rating: 4.9,
    percent: "N/A",
    difficulty: 3.6,
  },
  {
    instructor: "Kieserman, Brad",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Wood, Erik",
    rating: 3.1,
    percent: "50%",
    difficulty: 4.2,
  },
  {
    instructor: "Stern, Jeffrey",
    rating: 4.4,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Massaro, Vanessa",
    rating: 2.3,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Jones, Gregg",
    rating: 4.3,
    percent: "N/A",
    difficulty: 2.1,
  },
  {
    instructor: "Barnhart, Shaunna",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Frazier, Tim",
    rating: 4.1,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Tucker, Erinn",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Kim, Stephanie",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "McFarland, Mary",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Phillips, Glenn",
    rating: 4.8,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Gomez, German",
    rating: 3.3,
    percent: "75%",
    difficulty: 2,
  },
  {
    instructor: "Sims, Sonya",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Peterson, Courtney",
    rating: 1,
    percent: "N/A",
    difficulty: 5,
  },
  {
    instructor: "Babalola, Olusegun",
    rating: 2.7,
    percent: "60%",
    difficulty: 4.7,
  },
  {
    instructor: "Jones, Rhonda",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Smith, Gregory",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Dolan, Peter",
    rating: 4.5,
    percent: "80%",
    difficulty: 3.4,
  },
  {
    instructor: "Hasan, Ed",
    rating: 5,
    percent: "100%",
    difficulty: 4.8,
  },
  {
    instructor: "Rizzo, Matthew",
    rating: 4.3,
    percent: "0%",
    difficulty: 2.7,
  },
  {
    instructor: "Adeleye, Ifedapo",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Steitz, John",
    rating: 1,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Molina, Pablo",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "McCue, Sarah",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Mason, Jeffrey",
    rating: 3.5,
    percent: "34%",
    difficulty: 1.3,
  },
  {
    instructor: "Bennett, Lisa",
    rating: 3.6,
    percent: "79%",
    difficulty: 3.8,
  },
  {
    instructor: "Thomas, Sunela",
    rating: 2.2,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Cole, Alexander",
    rating: 3.9,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Sikes, Angela",
    rating: 3,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Carrol, Nora",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Bhargava, Sarita",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Jennings, Jeanne",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Richardson, Ronald",
    rating: 4.8,
    percent: "N/A",
    difficulty: 1.9,
  },
  {
    instructor: "Meshulam, Elad",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Bennett, Laura",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Stewart, Mary",
    rating: 3.8,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Long, Michael",
    rating: 4.5,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Lewis, Steven",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Dziemian, Neil",
    rating: 2,
    percent: "0%",
    difficulty: 4.5,
  },
  {
    instructor: "Mermelstein, Michelle",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Wilson, Laura",
    rating: 3.8,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Evans, Corbin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Charles, Matthew",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Reed, Martin",
    rating: 3.7,
    percent: "100%",
    difficulty: 2.4,
  },
  {
    instructor: "Borst, Jay",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Lepore, Dominic",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Mathews, Lisa",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Finn, Susan",
    rating: 4.1,
    percent: "86%",
    difficulty: 3.3,
  },
  {
    instructor: "Tessema, Dereje",
    rating: 5,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "DelGrosso, Steven",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Sone, Saya",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Cleveland, Simon",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Corso, John",
    rating: 5,
    percent: "100%",
    difficulty: 1.7,
  },
  {
    instructor: "Spead, Michael",
    rating: 1.7,
    percent: "0%",
    difficulty: 3.3,
  },
  {
    instructor: "Vallone, Joseph",
    rating: 2.7,
    percent: "50%",
    difficulty: 3.3,
  },
  {
    instructor: "Moschoglou, George",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Fanning, Michael",
    rating: 2.1,
    percent: "0%",
    difficulty: 3.5,
  },
  {
    instructor: "Williamson, Glenn",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "King, James",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Lambinicio, Glen",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Rudd, Michelle",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Brown, Lloyd",
    rating: 3,
    percent: "50%",
    difficulty: 3,
  },
  {
    instructor: "Pao, Roger",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Tibbs, Eugene",
    rating: 3.3,
    percent: "0%",
    difficulty: 3.4,
  },
  {
    instructor: "Rajewski, Robert",
    rating: 2.6,
    percent: "50%",
    difficulty: 3.4,
  },
  {
    instructor: "Hunter, Mark",
    rating: 4.6,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Wood, Brian",
    rating: 3.6,
    percent: "N/A",
    difficulty: 1.9,
  },
  {
    instructor: "Manley, Andrew",
    rating: 5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Cristiano, Robert",
    rating: 4.4,
    percent: "N/A",
    difficulty: 2.1,
  },
  {
    instructor: "Scully, Keith",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Briggs, Joe",
    rating: 2.2,
    percent: "37%",
    difficulty: 4.6,
  },
  {
    instructor: "Bockelman, Peter",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Jones, Christopher",
    rating: 4.7,
    percent: "N/A",
    difficulty: 3.4,
  },
  {
    instructor: "Christian, Marc",
    rating: 1,
    percent: "N/A",
    difficulty: 4.8,
  },
  {
    instructor: "Sheppard, Thomas",
    rating: 5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Harrington, Andrew",
    rating: 3.2,
    percent: "10%",
    difficulty: 2.8,
  },
  {
    instructor: "Thomas, Damion",
    rating: 3.7,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Brown, Taylor",
    rating: 4.9,
    percent: "N/A",
    difficulty: 2.6,
  },
  {
    instructor: "Tyahla, David",
    rating: 1,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Ivan, Emese",
    rating: 3.6,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Goodman, Stephen",
    rating: 4.4,
    percent: "100%",
    difficulty: 1.5,
  },
  {
    instructor: "Tucker, Allison",
    rating: 4,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Errington, Jeffrey",
    rating: 3,
    percent: "80%",
    difficulty: 3.5,
  },
  {
    instructor: "McGrath, John",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Gibson, David",
    rating: 3,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Burrell, Scott",
    rating: 4,
    percent: "N/A",
    difficulty: 2.5,
  },
  {
    instructor: "Hall, Kenneth",
    rating: 2.9,
    percent: "0%",
    difficulty: 3.5,
  },
  {
    instructor: "Stokes, Lisa",
    rating: 2.8,
    percent: "43%",
    difficulty: 3.6,
  },
  {
    instructor: "Devine, Kathy",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Loporcaro, Joseph",
    rating: 4.8,
    percent: "100%",
    difficulty: 1.8,
  },
  {
    instructor: "Jones, Ryan",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Sepulveda, Alexander",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Santos, Michael",
    rating: 3.3,
    percent: "40%",
    difficulty: 3.3,
  },
  {
    instructor: "Harris, Tasha",
    rating: 1.9,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Wilkinson, Frances",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Hirsch, Andrew",
    rating: 2.5,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Davis, Mary",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Lorenzen, Michael",
    rating: 4.6,
    percent: "N/A",
    difficulty: 1.6,
  },
  {
    instructor: "Frederick, Brian",
    rating: 3.5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Goldston, Justin",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Dunn, Linda",
    rating: 4.6,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Wolff, Aaron",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Coriale, David",
    rating: 3.3,
    percent: "67%",
    difficulty: 3.5,
  },
  {
    instructor: "Rivera-Piza, Horacio",
    rating: 1.8,
    percent: "0%",
    difficulty: 2.5,
  },
  {
    instructor: "Schmanske, Brian",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Santens, Michael",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Hogan, Karen",
    rating: 3.9,
    percent: "0%",
    difficulty: 3,
  },
  {
    instructor: "Spar, Daniel",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Stanford, Jean",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Murphy, Joshua",
    rating: 4.7,
    percent: "93%",
    difficulty: 2.7,
  },
  {
    instructor: "McCarthy, Ellen",
    rating: 1.9,
    percent: "32%",
    difficulty: 4,
  },
  {
    instructor: "Bradley, Richard",
    rating: 4.2,
    percent: "67%",
    difficulty: 3.7,
  },
  {
    instructor: "Gray, Whitney",
    rating: 4.7,
    percent: "N/A",
    difficulty: 1.3,
  },
  {
    instructor: "Zimmerman, Christopher",
    rating: 2.3,
    percent: "N/A",
    difficulty: 2.7,
  },
  {
    instructor: "Hartman, Ronald",
    rating: 2.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Parrott, W",
    rating: 3.6,
    percent: "67%",
    difficulty: 3.1,
  },
  {
    instructor: "Stearns, Deborah",
    rating: 2.9,
    percent: "32%",
    difficulty: 3.3,
  },
  {
    instructor: "Chiang, Jessica",
    rating: 3.2,
    percent: "58%",
    difficulty: 3.3,
  },
  {
    instructor: "Lyons, Ian",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Kushlev, Kostadin",
    rating: 3.6,
    percent: "80%",
    difficulty: 2.5,
  },
  {
    instructor: "Ryan, Rebecca",
    rating: 4.4,
    percent: "67%",
    difficulty: 2.8,
  },
  {
    instructor: "Bonior, Andrea",
    rating: 4.4,
    percent: "89%",
    difficulty: 2.3,
  },
  {
    instructor: "Johnson, Anna",
    rating: 4.4,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Green, Adam",
    rating: 4.2,
    percent: "84%",
    difficulty: 3.4,
  },
  {
    instructor: "Brown, Casey",
    rating: 3,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Moghaddam, Fathali",
    rating: 3.5,
    percent: "45%",
    difficulty: 2.8,
  },
  {
    instructor: "Phillips, Deborah",
    rating: 4.8,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Vaidya, Chandan",
    rating: 3.7,
    percent: "50%",
    difficulty: 2.5,
  },
  {
    instructor: "Woolard, Jennifer",
    rating: 3.7,
    percent: "74%",
    difficulty: 3.3,
  },
  {
    instructor: "Gordon, Nora",
    rating: 4,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Wallis, James",
    rating: 2.3,
    percent: "0%",
    difficulty: 3.6,
  },
  {
    instructor: "Calel, Raphael",
    rating: 4,
    percent: "100%",
    difficulty: 5,
  },
  {
    instructor: "Schone, Barbara",
    rating: 3.9,
    percent: "100%",
    difficulty: 4.3,
  },
  {
    instructor: "Mitchell, Jean",
    rating: 3.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Thomas, Adam",
    rating: 3.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Manoli, Dayanand",
    rating: 3.7,
    percent: "67%",
    difficulty: 4,
  },
  {
    instructor: "Kern, Andreas",
    rating: 4.8,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Hardy, Bradley",
    rating: 1.8,
    percent: "N/A",
    difficulty: 4.3,
  },
  {
    instructor: "Eissa, Nada",
    rating: 2.6,
    percent: "67%",
    difficulty: 3.5,
  },
  {
    instructor: "Leng, Ning",
    rating: 4.8,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Wise, Andrew",
    rating: 3.4,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Catilina, Eliane",
    rating: 3.4,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Johnson, David",
    rating: 2.1,
    percent: "0%",
    difficulty: 4.3,
  },
  {
    instructor: "Karaca, Zeynal",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Hines, Mark",
    rating: 4.2,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Kim, Hong",
    rating: 2.6,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Weaver, R",
    rating: 3.1,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Moussavi, Massoud",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Buttarazzi, John",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Bhattasali, Deepak",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Fleming, Matthew",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Dionne, EJ",
    rating: 3.5,
    percent: "50%",
    difficulty: 2,
  },
  {
    instructor: "Han, Jun",
    rating: 1.6,
    percent: "N/A",
    difficulty: 3.1,
  },
  {
    instructor: "Kyle, Robert",
    rating: 4.8,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Sullivan, Michael",
    rating: 3.5,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Gossart, John",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Stern, Alena",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Mahini, Robert",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Al-Shamma, Kate",
    rating: 5,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Neuendorf, Jill",
    rating: 4.6,
    percent: "94%",
    difficulty: 2.8,
  },
  {
    instructor: "Pechnikova, Anna",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.8,
  },
  {
    instructor: "Boudovskaia, Elena",
    rating: 1.7,
    percent: "28%",
    difficulty: 4.3,
  },
  {
    instructor: "Moser, Svetlana",
    rating: 5,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Gorski, Bradley",
    rating: 4.8,
    percent: "100%",
    difficulty: 3.7,
  },
  {
    instructor: "Mihaychuk, George",
    rating: 4.2,
    percent: "58%",
    difficulty: 3.4,
  },
  {
    instructor: "Fedorova, L",
    rating: 4.4,
    percent: "80%",
    difficulty: 2.3,
  },
  {
    instructor: "Meerson, Olga",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.3,
  },
  {
    instructor: "Smith, Kathleen",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Meyer, Kyle",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Sabonis-Helf, Theresa",
    rating: 4.7,
    percent: "93%",
    difficulty: 3.3,
  },
  {
    instructor: "Jones, Meg",
    rating: 3.6,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Mendenhall, Emily",
    rating: 4.6,
    percent: "90%",
    difficulty: 3.6,
  },
  {
    instructor: "Mathis, Jeremy",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Toohey, Brian",
    rating: 4,
    percent: "50%",
    difficulty: 1.5,
  },
  {
    instructor: "Opalo, Vanessa",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Montgomery, Mark",
    rating: 3.6,
    percent: "50%",
    difficulty: 3.1,
  },
  {
    instructor: "Lawrence, Christopher",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Carroll, Thomas",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Cheung, Ivan",
    rating: 3.9,
    percent: "0%",
    difficulty: 3.2,
  },
  {
    instructor: "Rhodes, Andrew",
    rating: 3.8,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Lickley, Megan",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Tarter, Andrew",
    rating: 4.7,
    percent: "N/A",
    difficulty: 1.7,
  },
  {
    instructor: "Schroeder, Richard",
    rating: 1,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Lieber, Keir",
    rating: 4.3,
    percent: "93%",
    difficulty: 3.1,
  },
  {
    instructor: "Tsarouhas, Dimitris",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Weigold, Keon",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Roberts, Kimberly",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Hilaire, Max",
    rating: 3.3,
    percent: "58%",
    difficulty: 3.7,
  },
  {
    instructor: "Wineman, Bradford",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Bryant, Susan",
    rating: 3.5,
    percent: "100%",
    difficulty: 3.2,
  },
  {
    instructor: "Sterling, Brent",
    rating: 4.3,
    percent: "80%",
    difficulty: 3.3,
  },
  {
    instructor: "Stout, Mark",
    rating: 5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Saunders, Elizabeth",
    rating: 4.5,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Robinson, Michael",
    rating: 3.4,
    percent: "60%",
    difficulty: 2.9,
  },
  {
    instructor: "Moller, Sara",
    rating: 4.3,
    percent: "75%",
    difficulty: 3.6,
  },
  {
    instructor: "Lucas, Jack",
    rating: 3.5,
    percent: "80%",
    difficulty: 4.3,
  },
  {
    instructor: "Goldstein, Joshua",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "May, Andrew",
    rating: 2.5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Murphy, Brian",
    rating: 4.4,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Rose, Caroline",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Grady, Sandra",
    rating: 2.3,
    percent: "25%",
    difficulty: 3.9,
  },
  {
    instructor: "Phillips, David",
    rating: 3.5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Mosher, David",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Bartels, Elizabeth",
    rating: 4.1,
    percent: "0%",
    difficulty: 1.8,
  },
  {
    instructor: "Abuza, Zachary",
    rating: 3.7,
    percent: "N/A",
    difficulty: 3.8,
  },
  {
    instructor: "Yung, Chris",
    rating: 3.3,
    percent: "67%",
    difficulty: 2.7,
  },
  {
    instructor: "Marat, Erica",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Patterson, Rebecca",
    rating: 4.3,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Byman, Daniel",
    rating: 4.4,
    percent: "75%",
    difficulty: 3.3,
  },
  {
    instructor: "Wall, Amanda",
    rating: 5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Robarge, David",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Modell, Scott",
    rating: 4.8,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "London, Doug",
    rating: 3.1,
    percent: "0%",
    difficulty: 1.8,
  },
  {
    instructor: "Lee, Caitlin",
    rating: 4.7,
    percent: "N/A",
    difficulty: 2.4,
  },
  {
    instructor: "Harper, Sarah",
    rating: 3.6,
    percent: "77%",
    difficulty: 2.3,
  },
  {
    instructor: "Lin, Frank",
    rating: 3.5,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Klein, John",
    rating: 4.1,
    percent: "N/A",
    difficulty: 2.9,
  },
  {
    instructor: "Omelicheva, Mariya",
    rating: 4,
    percent: "N/A",
    difficulty: 2.8,
  },
  {
    instructor: "Meese, Michael",
    rating: 4.5,
    percent: "N/A",
    difficulty: 3,
  },
  {
    instructor: "Hayes, Lauren",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Bennett, Gina",
    rating: 2.5,
    percent: "0%",
    difficulty: 4,
  },
  {
    instructor: "Doyle, Paula",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Mansourov, Alexandre",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Schultz, Tammy",
    rating: 4.5,
    percent: "N/A",
    difficulty: 4,
  },
  {
    instructor: "Brown, Jason",
    rating: 4,
    percent: "N/A",
    difficulty: 1,
  },
  {
    instructor: "Zilinskas, Rimas",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Bryant, Doug",
    rating: 4,
    percent: "67%",
    difficulty: 2.1,
  },
  {
    instructor: "Guidroz, Kathleen",
    rating: 3.2,
    percent: "50%",
    difficulty: 2.2,
  },
  {
    instructor: "Stiles, Sarah",
    rating: 4.7,
    percent: "94%",
    difficulty: 1.4,
  },
  {
    instructor: "Holland, Kimberlee",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Kato, Yuki",
    rating: 4.3,
    percent: "78%",
    difficulty: 2,
  },
  {
    instructor: "Perkins, Kristin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Newman, Alyssa",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "White, Gregory",
    rating: 4.4,
    percent: "84%",
    difficulty: 2,
  },
  {
    instructor: "McNamara, Dennis",
    rating: 4.2,
    percent: "50%",
    difficulty: 2.5,
  },
  {
    instructor: "Tyson, Karolyn",
    rating: 4.2,
    percent: "77%",
    difficulty: 3.7,
  },
  {
    instructor: "Shedd, Carla",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Leow, Ronald",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Goldman, Allison",
    rating: 2,
    percent: "0%",
    difficulty: 5,
  },
  {
    instructor: "Suarez De Cornejo, Ximen",
    rating: 3.7,
    percent: "82%",
    difficulty: 3.1,
  },
  {
    instructor: "Havenne, Maude",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Sciacca, Michael",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Biddanda, Varun",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Carvajal, Leonardo",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Ruiz, Sandra",
    rating: 4.1,
    percent: "N/A",
    difficulty: 4.8,
  },
  {
    instructor: "Thinglum, Anne",
    rating: 4.3,
    percent: "100%",
    difficulty: 2.7,
  },
  {
    instructor: "Cornejo, Francisco",
    rating: 4,
    percent: "93%",
    difficulty: 2.5,
  },
  {
    instructor: "Amador, Raysa",
    rating: 3.4,
    percent: "0%",
    difficulty: 3.2,
  },
  {
    instructor: "Smidakova, Bohumira",
    rating: 4.5,
    percent: "94%",
    difficulty: 3,
  },
  {
    instructor: "Simorangkir, Monica",
    rating: 4.6,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Maggi, Diego",
    rating: 4.5,
    percent: "100%",
    difficulty: 1.5,
  },
  {
    instructor: "Gevorgyan, Tatevik",
    rating: 4,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Maccarone, Rahma",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Ortega Luque, Angeles",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Doniz, Zyanya",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Robinson, Annie",
    rating: 4.7,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Fernandez, Victor",
    rating: 1,
    percent: "67%",
    difficulty: 3,
  },
  {
    instructor: "Ladino, Aned",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Pereira, Alex",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Levenson, Ana",
    rating: 4,
    percent: "90%",
    difficulty: 3.2,
  },
  {
    instructor: "Stewart, Daniel",
    rating: 1.4,
    percent: "N/A",
    difficulty: 4.9,
  },
  {
    instructor: "Vander Wel, Austin",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Finnegan, Abbie",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Ornelles, Annie",
    rating: 5,
    percent: "100%",
    difficulty: 2.3,
  },
  {
    instructor: "Yarbrough, Alyssa",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Moreno, Maria",
    rating: 4.2,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Edney, Drue",
    rating: 3.5,
    percent: "50%",
    difficulty: 3.5,
  },
  {
    instructor: "Vargas Henao, Katherin",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Nikolic, Maja",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "McKeon, Conor",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Borowitz, Molly",
    rating: 4.3,
    percent: "80%",
    difficulty: 2.2,
  },
  {
    instructor: "Larubia-Prado, Francisco",
    rating: 4.1,
    percent: "100%",
    difficulty: 3.4,
  },
  {
    instructor: "Moreno, Sebastian",
    rating: 3.6,
    percent: "67%",
    difficulty: 3.4,
  },
  {
    instructor: "Gonzalez Barajas, Leopoldo",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Salgado Portillo, Cesar",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Santos, Vivaldo",
    rating: 4,
    percent: "50%",
    difficulty: 3,
  },
  {
    instructor: "Ramirez, Sara",
    rating: 4.1,
    percent: "89%",
    difficulty: 2.6,
  },
  {
    instructor: "Morales-Front, Alfonso",
    rating: 3.9,
    percent: "84%",
    difficulty: 2.6,
  },
  {
    instructor: "Sanz, Cristina",
    rating: 4.1,
    percent: "100%",
    difficulty: 3.5,
  },
  {
    instructor: "Yarza, Alejandro",
    rating: 4.4,
    percent: "88%",
    difficulty: 2.5,
  },
  {
    instructor: "Lifshey, Adam",
    rating: 4,
    percent: "67%",
    difficulty: 3,
  },
  {
    instructor: "Ferreira, Michael",
    rating: 3.7,
    percent: "75%",
    difficulty: 1.7,
  },
  {
    instructor: "Tosi, Justin",
    rating: 3.8,
    percent: "100%",
    difficulty: 3.7,
  },
  {
    instructor: "Akhtar, Sahar",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Quinn, Dennis",
    rating: 4.1,
    percent: "100%",
    difficulty: 2.9,
  },
  {
    instructor: "Sawka, Kenneth",
    rating: 4,
    percent: "50%",
    difficulty: 3.5,
  },
  {
    instructor: "Brennan, Jason",
    rating: 4.4,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Dunahay, David",
    rating: 4.5,
    percent: "90%",
    difficulty: 3.1,
  },
  {
    instructor: "English, William",
    rating: 4.7,
    percent: "100%",
    difficulty: 1.9,
  },
  {
    instructor: "Liu, Lizhi",
    rating: 4.4,
    percent: "84%",
    difficulty: 2.8,
  },
  {
    instructor: "Whitener, Mark",
    rating: 3.5,
    percent: "61%",
    difficulty: 3,
  },
  {
    instructor: "Verma, Dinesh",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "DiGiorgi, Alicia",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Marshall, Sarah",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
  {
    instructor: "Goldman, Derek",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Werpehowski, William",
    rating: 4.2,
    percent: "84%",
    difficulty: 2.8,
  },
  {
    instructor: "Goines, Beverly",
    rating: 3.2,
    percent: "54%",
    difficulty: 3.2,
  },
  {
    instructor: "Steenhuisen, Lauve",
    rating: 4.3,
    percent: "97%",
    difficulty: 2.7,
  },
  {
    instructor: "Crowley-Buck, John",
    rating: 5,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Cintron, Christian",
    rating: 5,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Bonnette, Kathleen",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Gertz, Steven",
    rating: 3,
    percent: "67%",
    difficulty: 3.8,
  },
  {
    instructor: "Morici, Rosanne",
    rating: 4.2,
    percent: "96%",
    difficulty: 2.4,
  },
  {
    instructor: "Lamm, Julia",
    rating: 3.5,
    percent: "44%",
    difficulty: 3.5,
  },
  {
    instructor: "Sayilgan, Mehmet",
    rating: 4.2,
    percent: "92%",
    difficulty: 1.8,
  },
  {
    instructor: "Slater, Michael",
    rating: 4.1,
    percent: "72%",
    difficulty: 2.6,
  },
  {
    instructor: "Zaina, Lisa",
    rating: 4.5,
    percent: "89%",
    difficulty: 2.6,
  },
  {
    instructor: "Oskvig, Bryant",
    rating: 4.4,
    percent: "79%",
    difficulty: 1.4,
  },
  {
    instructor: "OConnell, Luke",
    rating: 4.3,
    percent: "85%",
    difficulty: 3.1,
  },
  {
    instructor: "Cho, Min-Ah",
    rating: 4.3,
    percent: "94%",
    difficulty: 2.3,
  },
  {
    instructor: "Kim, Ray",
    rating: 3,
    percent: "100%",
    difficulty: 2.8,
  },
  {
    instructor: "Akinade, Akintunde",
    rating: 4.3,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Shabana, Ayman",
    rating: 4,
    percent: "N/A",
    difficulty: 2.2,
  },
  {
    instructor: "Lupu, Alexandra",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Mitchell, Alan",
    rating: 4.3,
    percent: "92%",
    difficulty: 2.3,
  },
  {
    instructor: "Koester, Anne",
    rating: 4.6,
    percent: "90%",
    difficulty: 1.4,
  },
  {
    instructor: "Sharan, Brahmachari",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.2,
  },
  {
    instructor: "Butticci, Annalisa",
    rating: 2.8,
    percent: "45%",
    difficulty: 2.2,
  },
  {
    instructor: "Yanko, Catherine",
    rating: 2.7,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Daniels, Kim",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Lefebure, Leo",
    rating: 4,
    percent: "89%",
    difficulty: 2.5,
  },
  {
    instructor: "Hollenbach, David",
    rating: 3,
    percent: "50%",
    difficulty: 4,
  },
  {
    instructor: "Wilson, Stephen",
    rating: 4.4,
    percent: "89%",
    difficulty: 2.8,
  },
  {
    instructor: "Antus, Elizabeth",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.6,
  },
  {
    instructor: "Siddiqui, Sohaira",
    rating: 4.1,
    percent: "N/A",
    difficulty: 3.1,
  },
  {
    instructor: "Steck, Christopher",
    rating: 3.2,
    percent: "54%",
    difficulty: 2.8,
  },
  {
    instructor: "Glucklich, Ariel",
    rating: 3.5,
    percent: "50%",
    difficulty: 2.9,
  },
  {
    instructor: "Folan, Peter",
    rating: 4.2,
    percent: "84%",
    difficulty: 3.4,
  },
  {
    instructor: "Phan, Peter",
    rating: 4.1,
    percent: "88%",
    difficulty: 2.4,
  },
  {
    instructor: "Hudson, Barry",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Miller, Philip",
    rating: 4.8,
    percent: "N/A",
    difficulty: 3.5,
  },
  {
    instructor: "Rodriguez, Olga",
    rating: 4.5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Albanese, Chris",
    rating: 4.4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Gur, Zeynep",
    rating: 5,
    percent: "N/A",
    difficulty: 2,
  },
  {
    instructor: "Ciddi, Sinan",
    rating: 4.8,
    percent: "100%",
    difficulty: 2.5,
  },
  {
    instructor: "Colbert, Soyica",
    rating: 3.4,
    percent: "34%",
    difficulty: 3,
  },
  {
    instructor: "Wisler, Andria",
    rating: 4.5,
    percent: "N/A",
    difficulty: 3.2,
  },
  {
    instructor: "Hurley, Katherine",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Danylevich, Theodora",
    rating: 4.7,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Kenney, Kristie",
    rating: 5,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Warner, Judith",
    rating: 4.1,
    percent: "N/A",
    difficulty: 2.3,
  },
  {
    instructor: "Lewis, Abigail",
    rating: 3.6,
    percent: "50%",
    difficulty: 3.7,
  },
  {
    instructor: "Hosseini, Fatemeh",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Floyd, Brian",
    rating: 3.5,
    percent: "0%",
    difficulty: 2.4,
  },
  {
    instructor: "Wright, John",
    rating: 3.3,
    percent: "N/A",
    difficulty: 3.8,
  },
  {
    instructor: "Lipscomb, David",
    rating: 4.2,
    percent: "88%",
    difficulty: 2.9,
  },
  {
    instructor: "Malloy, Michael",
    rating: 3,
    percent: "0%",
    difficulty: 1,
  },
  {
    instructor: "Park, You-me",
    rating: 3.7,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Christopher, Emerald",
    rating: 4.5,
    percent: "90%",
    difficulty: 2,
  },
  {
    instructor: "Nourbakhsh, Safoura",
    rating: 3.1,
    percent: "54%",
    difficulty: 3.8,
  },
  {
    instructor: "Forrest, Brady",
    rating: 3.2,
    percent: "40%",
    difficulty: 3,
  },
  {
    instructor: "Velez, Elizabeth",
    rating: 3.9,
    percent: "100%",
    difficulty: 3.1,
  },
  {
    instructor: "Kovach, Laura",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Biermayr-Jenzano, Patricia",
    rating: 4,
    percent: "75%",
    difficulty: 2.5,
  },
  {
    instructor: "Hoefling, Tricia",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Johnson, Amena",
    rating: 4,
    percent: "100%",
    difficulty: 3,
  },
  {
    instructor: "Brazile, Donna",
    rating: 3.2,
    percent: "30%",
    difficulty: 2.4,
  },
  {
    instructor: "Maxwell, Monica",
    rating: 5,
    percent: "100%",
    difficulty: 1.2,
  },
  {
    instructor: "Rudd, LeeAnn",
    rating: 5,
    percent: "N/A",
    difficulty: 1.8,
  },
  {
    instructor: "Day, Stacy",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Tarsa, Rebecca",
    rating: 4.5,
    percent: "87%",
    difficulty: 2.5,
  },
  {
    instructor: "Bieda, Casey",
    rating: 5,
    percent: "50%",
    difficulty: 2,
  },
  {
    instructor: "Benson, Schuler",
    rating: 5,
    percent: "100%",
    difficulty: 2,
  },
  {
    instructor: "Palmeri, Jason",
    rating: 4.9,
    percent: "100%",
    difficulty: 1.8,
  },
  {
    instructor: "Gretes, Andrew",
    rating: 0,
    percent: "100%",
    difficulty: 0,
  },
  {
    instructor: "Seifert, Nick",
    rating: 4,
    percent: "100%",
    difficulty: 1,
  },
  {
    instructor: "Atherton, Daniel",
    rating: 0,
    percent: "N/A",
    difficulty: 0,
  },
  {
    instructor: "Chehak, Molly",
    rating: 5,
    percent: "100%",
    difficulty: 4,
  },
];

export default instructors;
